import React from "react";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import { Link,useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../Helper/ApiCall/ApiCall";

const AddPageHeader = ({ headerData }) => {
  const navigate = useNavigate();
  const handleLogout = async () => {
    const urlString = `auth/logout`;
    const result = await axiosInstance.post(urlString);
    if (result.status === 204) {
      window.localStorage.clear();
      navigate('/login')
    }
    else if(result?.response?.data?.message==='Json Web Token is Expired, Try again '){
      navigate("/login");
    }
  };

  return (
    <div className="header header--add-page">
      <div className="header__left">
        <div>
          <p className="header__title-div__title">{headerData.title}</p>
          <p className="header__title-div__subTitle">
            <span style={{ color: "#0099FF", marginLeft: "0px" }}>
              {headerData.subTitleLeft}
            </span>{" "}
            {headerData.subTitleRight}
          </p>
        </div>
      </div>
      {headerData.updateBtnText && (
        <div className="header__right--only-btn">
          <Link to={headerData.navigatePath} className="header__addBtn">
            <span className="header__updateBtn-icon">
              <BsIcons.BsCheck2Square />
            </span>
            {headerData.updateBtnText}
          </Link>
        </div>
      )}
      {headerData.logoutBtnText && (
        <div className="header__right--only-btn">
          <button onClick={handleLogout} className="header__addBtn">
            <span className="header__updateBtn-icon">
              <MdIcons.MdLogout />
            </span>
            {headerData.logoutBtnText}
          </button>
        </div>
      )}
    </div>
  );
};

export default AddPageHeader;
