import React from "react";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <div className="policy">
      <header>Privacy Policy: mVOR</header>
      <main>
        <section>
          <div>
            <p className={"policy__date"}>
              Latest Update: <span>April 26, 2023</span>.{" "}
            </p>
            <br />
            <br />
            This Privacy Policy describes our policies and procedures on the
            collection, use and disclosure of Your information when You use our
            service through our application and tells You about Your privacy
            rights. We use Your personal data to provide and improve the
            service. By using our service, You agree to the collection and use
            of information in accordance with this Privacy Policy.
          </div>
        </section>
        <section>
          <h1>Purpose of the application</h1>
          <div>
            There is a popular test called Head Impulse Test (HIT) which collects Vestibular Ocular Reflex data, and requires a costly device. This test can help to discriminate whether the vertigo is due to a stroke, or to peripheral vestibular neuritis. Our app is targeting to collect this VOR data using ARKit. We have measured head velocity in 2 directions horizontally in the current version. We will help doctors to analyze the data and make sure if we can really replace the costly device with this app. We have also added another test called Gaze Stabilization Test (GST).This test has a total of 3 steps. In the first step we will calculate the user’s Static Visual Acuity (SVA). After that in the second step with the help of SVA we will calculate the user's Visual Processing Time (VPT). Thirdly, in the final step with the help of the values from the first and second step we will perform the user's GST. In these three steps we also had to add the Speech Recognition feature during the test. All these Calculations are being done with the help of ARKit and Speech framework. This is a primary stage of our noble aim. We will have several calculations in the upcoming versions.
            <br />
            <br />
            There are some top-class publication (even in Nature.com) regarding
            VOR data. We are trying to bring that idea by creating an iOS app in
            collaboration with{" "}
            <strong>
              <a
                href="https://www.marquette.edu/computer-science/directory/iqbal-ahamed.php"
                target="blank"
              >
                {" "}
                Dr. Sheikh Iqbal Ahmed{" "}
              </a>
            </strong>{" "}
            (Wehr Professor and Founding Chair of Computer Science Department,
            Marquette University; the Standing Committee Vice Chair, IEEE
            COMPSAC; General Chair, IEEE Digital Health)
          </div>
          <h2>Procedures</h2>
          <div>
            If any user wants to participate in our app, they have to contact
            ubitrix assigned doctors. The doctors will describe the policies to
            the participants. If a participant agrees, then doctor will create
            an account for him/her and then share a login code.<br/> Finally,
            participant will use the login code to participate in our app.
          </div>
          <h2>Risks</h2>
          <div>
            We assure that participant will face no risk while using the app.
            The doctors will let them know about the process (outside the app)
            and also we have privacy policy hyperlink, instructions (inside the
            app).
            <br/>
            We have the permission from IRB which assures our policy is granted by the top class organisation.
          </div>
          <h2>Benefits</h2>
          <div>
            You as a participant can be a part of a noble research by using the
            app. We are aiming to replace a costly device which is used to
            collect VOR data. If we are successful, there will be great
            breakthrough in medical science.
          </div>
        </section>
        <section>
          <h1>Collecting and Using Your Personal Data</h1>
          <h2>Voice input</h2>
          <div>
            We have used Apple’s speech framework to perform speech recognition in the Gaze Stabilization Test (GST).
            <ul>
              <li>We don’t collect the voice recording data.</li>
              <li>We only perform speech recognition during the GST.</li>
            </ul>
          </div>
          <h2>Personal Data</h2>
          <div>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact,
            identify or analyse You. Personal information includes:
            <ul>
              <li>Email Address</li>
              <li>Name</li>
              <li>Head Movement data</li>
              <li>Eye Gaze Movement data</li>
              <li>Usage Data</li>
            </ul>
          </div>
          <h2>Usage Data</h2>
          <div>
            Usage Data is collected automatically when using the Service. Usage
            Data may include information such as Your Device's Internet Protocol
            address (e.g. IP address), browser type, browser version, the pages
            of our Service that You visit, the time and date of Your visit, the
            time spent on those pages, unique device identifiers and other
            diagnostic data. When You access the Service by or through a mobile
            device, We may collect certain information automatically, including,
            but not limited to, the type of mobile device You use, Your mobile
            device unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data. We may also
            collect information that Your browser sends whenever You visit our
            Service or when You access the Service by or through a mobile
            device.
          </div>
        </section>

        <section>
          <h1>Use of Your Personal Data</h1>
          <div>
            The Company may use Personal Data for the following purpose
            <ul>
              <li>
                <strong>For research purpose: </strong> We will mainly use Your
                personal data for our research and analysis purposes as this is
                a research focused application. Our app will not provide any
                diagnosis right now. There is a popular test called Head Impulse
                test which collects Vestibular Occular Reflex data, and requires
                a costly device. This test can help to discriminate whether the
                vertigo is due to a stroke, or to peripheral vestibular
                neuritis. Our app is targeting to collect this VOR data using
                ARKit. We have measured head velocity in 4 directions in the
                current version. We will help doctors to analyze the data and
                make sure if we can really replace the costly device with this
                app. This is a primary stage of our noble aim. We will have
                several calculations in the upcoming versions.
              </li>
              <li>
                <strong>To provide and maintain our Service:</strong> including
                to monitor the usage of our Service.
              </li>
              <li>
                <strong>To manage Your account:</strong> to manage Your
                registration as a user of the Service. The Personal Data You
                provide can give You access to different functionalities of the
                Service that are available to You as a registered user.
              </li>
              <li>
                <strong>To contact You:</strong> To contact You by email,
                telephone calls, SMS, or other equivalent forms of electronic
                communication, such as a mobile application's push notifications
                regarding updates or informative communications related to the
                functionalities, products or contracted services, including the
                security updates, when necessary or reasonable for their
                implementation.
              </li>
            </ul>
          </div>
        </section>
        <section>
          <h1>TrueDepth API data & usage:</h1>
          We will get the head velocity data of the user in 2 directions which are left and right using ARKit's TrueDepth API. Information collected via TrueDepth API is position, orientation, rotation, and velocity of eye gaze movement and nose movement in degrees per second.
          <br />
          <br />
          We assure that using TrueDepth API-
          <ul>
            <li>
              We do not share any data with third party that is collected from
              TrueDepth API(head velocity).
            </li>
            <li>We do not store your location</li>
            <li>We do not collect any personal data from our users</li>
            <li>We do not collect and share any personal information</li>
            <li>No ad companies collect data through our service</li>
          </ul>
          <br />

          The Summary of the tests is like below:

          <p><strong>Head Impulse Test (HIT):</strong></p>
          <ul>
            <li>
              Side to side (left to right, or right to left) movement of head
            </li>
            <li>
              User fixates their gaze on a dot in the middle of the mobile
              screen
            </li>
            <li>
              Mobile app will output the position, rotation angle, and angular velocities of the eyes and nose.
            </li>
          </ul>

          <p><strong>Gaze Stability Test (GST):</strong></p>
          <ul>
            <li>
              Users fixate their gaze on a dot in the middle of the screen and move their head  quickly from left to right (like a pendulum) while looking at the screen and identifying the orientation of a presented target (letter E).
            </li>
            <li>
              In the first step we will determine the user's static visual acuity.
            </li>
            <li>
              In the second step  we will determine the user's Visual Processing Time.
            </li>
            <li>
              In the third step there will be a metronome sound playing in the background. Users will move their head with the rhythm of that sound horizontally. With help of the data collected in the first and second step we will perform the Gaze Stabilization Test (GST).
            </li>
            <li>
              This step determines the user’s maximal head velocity during active head rotations in the horizontal (yaw) plane at which they can maintain visual acuity.This step determines the user’s maximal head velocity during active head rotations in the horizontal (yaw) plane at which they can maintain visual acuity.
            </li>
          </ul>

        </section>
        <section>
          <h1>Retention of Your Personal Data</h1>
          <div>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain Your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies. The Company will also
            retain Usage Data for internal analysis purposes. Usage Data is
            generally retained for a shorter period of time, except when this
            data is used to strengthen the security or to improve the
            functionality of Our Service, or We are legally obligated to retain
            this data for longer time periods.
          </div>
        </section>
        <section>
          <h1>Transfer of Your personal data</h1>
          <div>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction. Your consent to this Privacy
            Policy followed by Your submission of such information represents
            Your agreement to that transfer. The Company will take all steps
            reasonably necessary to ensure that Your data is treated securely
            and in accordance with this Privacy Policy and no transfer of Your
            Personal Data will take place to an organization or a country unless
            there are adequate controls in place including the security of Your
            data and other personal information.
          </div>
        </section>
        <section>
          <h1>Security of Your personal Data</h1>
          <div>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data. Your
            sensitive information will be stored in a secure location with
            restricted access. All Your sensitive data will be encrypted to
            ensure extra security.
          </div>
        </section>
        <section>
          <h1>Contact US</h1>
          <div>
            If You have any questions about this Privacy Policy, You can contact
            us:
            <ul>
              <li>
                Send us email at{" "}
                <a href="mailto: driqbal.ubicomp@gmail.com">
                  {" "}
                  driqbal.ubicomp@gmail.com
                </a>
              </li>
              <li>
                Visit our website{" "}
                <a href="http://ubitrix.com/contact/">www.ubitrix.com</a>
              </li>
            </ul>
          </div>
        </section>
      </main>
    </div>
  );
};

export default PrivacyPolicy;
