import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Login from "../Login/Login";
import AdminList from "../../Admins/AdminList/AdminList";
import NoMatch from "../../NoMatch/NoMatch";
import UserListReport from "../../Report/UserListReport/UserListReport";
import AdminProfile from "../../Settings/AdminProfile/AdminProfile";
import PendingProfile from "../../Settings/PendingProfile/PendingProfile";
import UpdateAdminProfile from "../../Settings/UpdateAdminProfile/UpdateAdminProfile";
import AddUser from "../../Users/AddUser/AddUser";
import UserDetails from "../../Users/UserDetails/UserDetails";
import UserList from "../../Users/UserList/UserList";

import { ActiveUserRoute } from "../ActiveUserRoute/ActiveUserRoute";
import { PendingUserRoute } from "../PendingUserRoute/PendingUserRoute";
import { PrivateRoute } from "../PrivateRouting/PrivateRoute";
import { SuperRoute } from "../SuperRoute/SuperRoute";
import PrivacyPolicy from "../../PrivacyPolicy/PrivacyPolicy";

const MainRoute = () => {
  const [accessToken,setAccessToken]=useState(null);
  const [status,setStatus]=useState(null);
  const location = useLocation();
  useEffect(() => {
      let token = localStorage.getItem("accessToken");
      let userStatus = localStorage.getItem("status");
      setAccessToken(token);
      setStatus(userStatus)
  }, [location]);
  return (

    <div>
      <Routes>
        {accessToken !== null ? (
          status === "active" ? (
            <>
              <Route exact path="/" element={<UserList />} />
            </>
          ) : (
            <>
              <Route exact path="/" element={<PendingProfile />} />
            </>
          )
        ) : (
          <>
            <Route exact path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
          </>
        )}

        <Route
          path="/adminList"
          element={
            <PrivateRoute>
              <SuperRoute>
                <AdminList />
              </SuperRoute>
            </PrivateRoute>
          }
        />
        <Route
          path="/userList"
          element={
            <PrivateRoute>
              <ActiveUserRoute>
                <UserList />
              </ActiveUserRoute>
            </PrivateRoute>
          }
        />
        <Route
          path="/addUser"
          element={
            <PrivateRoute>
              <ActiveUserRoute>
                <AddUser />
              </ActiveUserRoute>
            </PrivateRoute>
          }
        />
        <Route
          path="/user/:id"
          element={
            <PrivateRoute>
              <ActiveUserRoute>
                <UserDetails />
              </ActiveUserRoute>
            </PrivateRoute>
          }
        />
        <Route
          path="/adminProfile"
          element={
            <PrivateRoute>
              <ActiveUserRoute>
                <AdminProfile />
              </ActiveUserRoute>
            </PrivateRoute>
          }
        />
        <Route
          path="/updateAdminProfile"
          element={
            <PrivateRoute>
              <ActiveUserRoute>
                <UpdateAdminProfile />
              </ActiveUserRoute>
            </PrivateRoute>
          }
        />
        <Route
          path="/report"
          element={
            <PrivateRoute>
              <ActiveUserRoute>
                <UserListReport />
              </ActiveUserRoute>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/pending"
          element={
            <PrivateRoute>
              <PendingUserRoute>
                <PendingProfile />
              </PendingUserRoute>
            </PrivateRoute>
          }
        />
        <Route path = "/privacy-policy" element = {<PrivacyPolicy/>}/>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
};

export default MainRoute;
