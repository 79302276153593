import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentItems: [],
  activitiesItems: [],
};

const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setReduxCurrentItems: (state, action) => {
      state.currentItems = action.payload;
    },
    setActivitiesItems: (state, action) => {
      state.activitiesItems = action.payload;
    },
    backToInitialActivitiesItems: (state) => {
      state.activitiesItems = [];
    },
    backToInitialCurrentItems: (state) => {
      state.currentItems = [];
    },
  },
});

export const {
  setReduxCurrentItems,
  backToInitialCurrentItems,
  setActivitiesItems,
  backToInitialActivitiesItems,
} = paginationSlice.actions;
export default paginationSlice.reducer;
