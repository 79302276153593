import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "../../Pagination/Pagination";
import Sidebar from "../../Sidebar/Sidebar";
import ListHeader from "../../Header/ListHeader/ListHeader";
import UsersTable from "../../Table/UsersTable/UsersTable";
import "./UserList.scss";
import {
  activePageSet,
  activeSubPageSet,
  sellSpanSet,
} from "../../../redux/sidebarSlice";
import { backToInitialCurrentItems } from "../../../redux/paginationSlice";
import { backToInitialUserSearch, setAllUsers } from "../../../redux/userSlice";
import { userSearch } from "../../../Helper/UserSearch/UserSearch";
import { axiosInstance } from "../../../Helper/ApiCall/ApiCall";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [dbUsers, setDbUsers] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const show = useSelector((state) => state.sidebar.show);
  const searchText = useSelector((state) => state.user.userSearchText);
  const allUsers = useSelector((state) => state.user.allUsers);
  const reduxCurrentItems = useSelector(
    (state) => state.pagination.currentItems
  );

  const userProps = {
    title: "Users",
    subTitleLeft: "Current Data",
    subTitleRight: `of ${users?.length} users`,
    placeholder: "by name,email & code",
    page: "User",
  };

  const getUsers = async () => {
    const urlString = "users";
    let result = await axiosInstance.get(urlString);
    if (result?.data?.success === true) {
      const newUsers = result?.data?.data;
      newUsers.map((user, i) => {
        user.serial = i + 1;
        user.gst_session_count = user?.gst.length || 0;
        user.hit_session_count = user?.hit.length || 0;
      });
      dispatch(setAllUsers(newUsers))
    }else if(result?.response?.data?.message==='Json Web Token is Expired, Try again '){
      navigate("/login");
    } else {
      dispatch(setAllUsers([]))
    }
  };

  useEffect(() => {
    if(allUsers.length<=0){
      getUsers()
    }
  }, []);


  useEffect(() => {
    if(allUsers.length>0){
      setUsers(allUsers);
      setDbUsers(allUsers);
    }
  }, [allUsers]);


  useEffect(() => {
    dispatch(backToInitialUserSearch());
    dispatch(backToInitialCurrentItems());
    dispatch(activePageSet(0));
    dispatch(activeSubPageSet("0.0"));
  }, []);
  useEffect(()=>{
    if(show){
      dispatch(sellSpanSet(1));
    }
  },[show])

  useEffect(() => {
    if (searchText !== "") {
      const newUsers = userSearch(dbUsers, searchText);
      setUsers(newUsers);
    } else {
      setUsers(dbUsers);
    }
  }, [searchText, dbUsers]);

  useEffect(() => {
    setCurrentUsers(reduxCurrentItems);
  }, [reduxCurrentItems]);

  return (
    <div className="page">
      <Sidebar />
      <div
        className={`page__container ${
          show ? "page__container--small" : "page__container--expand"
        }`}
      >
        <div className="page__container__header">
          <ListHeader
            headerData={userProps}
            items={dbUsers}
            setState={setUsers}
          />
        </div>
        {users.length > 0 ? (
          <div className="page__container__table-div">
            <UsersTable
              page={"UserList"}
              users={currentUsers}
              allUsers={users}
              setUsers={setUsers}
              getUsers={getUsers}
            />
          </div>
        ) : (
          <p className="page__container__no-match">No items found..</p>
        )}
        {users.length > 0 && (
          <div className="page__container__pagination-div">
            <Pagination items={users} />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserList;
