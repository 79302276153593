import MainRoute from "./Components/Authentication/MainRoute/MainRoute";

function App() {
  return (
    <div>
      <MainRoute />
    </div>
  );
}

export default App;
