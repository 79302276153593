import React from "react";
import "./ConfirmDeleteCard.scss";

const ConfirmDeleteCard = ({ handleDelete, setDeleteItem }) => {
  const deleteEvent = () => {
    handleDelete();
  };
  const cancelEvent = () => {
    setDeleteItem("");
  };
  return (
    <div className="delete-card">
      <p className="delete-card__title">Confirm Delete ?</p>
      <div className="delete-card__btn-div">
        <div className="delete-card__btn-div__deleteBtn" onClick={deleteEvent}>
          Delete
        </div>
        <div className="delete-card__btn-div__cancelBtn" onClick={cancelEvent}>
          Cancel
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteCard;
