import { configureStore } from "@reduxjs/toolkit";
import sidebarReducer from "./sidebarSlice";
import paginationReducer from "./paginationSlice";
import userSlice from "./userSlice";
import adminSlice from "./adminSlice";

export default configureStore({
  reducer: {
    sidebar: sidebarReducer,
    pagination: paginationReducer,
    user: userSlice,
    admin: adminSlice,
  },
  devTools: process.env.NODE_ENV !== "production",
});
