import React from "react";
import { Navigate, useLocation } from "react-router-dom";

export const ActiveUserRoute = ({ children }) => {
  let status = localStorage.getItem("status");
  const location = useLocation();

  return status === "active" ? (
    children
  ) : (
    <Navigate to="*" replace state={{ from: location }} />
  );
};
