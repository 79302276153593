import React from "react";
import "./NoMatch.scss";

const NoMatch = () => {
  return (
    <div className="not-found-page">
      <div className="not-found-page__body">
        <div className="not-found-page__body-left">
          <p className="not-found-page__body-left__code">404</p>
        </div>
        <div>
          <p className="not-found-page__right-text">
            This page could not be found
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoMatch;
