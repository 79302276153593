import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import * as BsIcons from "react-icons/bs";
import filterIcon from "../../../images/filter.png";
import exportIcon from "../../../images/exportFile.png";
import "./ListHeader.scss";
import ActivitiesFilter from "../../Filter/ActivitiesFilter/ActivitiesFilter";
import AdminFilter from "../../Filter/AdminFilter/AdminFilter";
import UserFilter from "../../Filter/UserFilter/UserFilter";
import {
  backToInitialUserActivitySearch,
  backToInitialUserSearch,
  setUserActivitySearchText,
  setUserSearchText,
} from "../../../redux/userSlice";
import {
  backToInitialAdminSearch,
  setAdminSearchText,
} from "../../../redux/adminSlice";
import { reportExport } from "../../../Helper/ReportExport/ReportExport";

const ListHeader = ({ headerData, items, setState }) => {
  const [searchData, setSearchData] = useState("");
  const [showFilterDiv, setShowFilterDiv] = useState(false);

  const dispatch = useDispatch();
  const selected = useSelector((state) => state.user.selectedUsers);

  const handleFilterDiv = () => {
    setShowFilterDiv(!showFilterDiv);
  };

  const handleSearchData = (e) => {
    setSearchData(e.target.value);
    if (e.target.value === "") {
      dispatch(backToInitialUserSearch());
      dispatch(backToInitialAdminSearch());
      dispatch(backToInitialUserActivitySearch());
    }
  };

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      if (headerData.page === "User") {
        dispatch(setUserSearchText(searchData));
      }
      if (headerData.page === "UserReport") {
        dispatch(setUserSearchText(searchData));
      }
      if (headerData.page === "UserDetails") {
        dispatch(setUserActivitySearchText(searchData));
      }
      if (headerData.page === "AdminList") {
        dispatch(setAdminSearchText(searchData));
      }
    }
  };

  const [report, setReport] = useState({
    data: [],
    headers: [],
    filename: "Report.csv",
  });
  const handleExportReport = () => {
    const csvReport = reportExport(items, selected);
    setReport(csvReport);
  };
  return (
    <div className="header">
      <div
        className={`header__left ${
          headerData.page === "UserReport" && "header__left--extra-btn"
        }`}
      >
        <div className="header__title-div">
          <p className="header__title-div__title">{headerData.title}</p>
          <p className="header__title-div__subTitle">
            <span style={{ color: "#06cfc7", marginLeft: "0px" }}>
              {headerData.subTitleLeft}
            </span>{" "}
            {headerData.subTitleRight}
          </p>
        </div>
        {headerData.page === "UserReport" && (
          <>
            {selected.length > 0 ? (
              <CSVLink
                {...report}
                className="header__exportBtn header__exportBtn--active"
                onClick={handleExportReport}
              >
                <img className="header__export-icon" src={exportIcon} alt="" />
                Export Report
              </CSVLink>
            ) : (
              <Link
                to="#"
                onClick={handleExportReport}
                className={`header__exportBtn`}
              >
                <img className="header__export-icon" src={exportIcon} alt="" />
                Export Report
              </Link>
            )}
          </>
        )}
      </div>
      <div
        className={`header__right ${
          headerData.page === "UserReport" && "header__right--extra-btn"
        }`}
      >
        <div
          className={`header__search-div ${
            headerData.page === "UserReport" && "header__search-div--report"
          }`}
        >
          <span
            className={`header__search-icon ${
              searchData && "header__search-icon--active"
            }`}
          >
            <BsIcons.BsSearch />
          </span>
          <input
            className="header__search-field"
            type="text"
            onChange={handleSearchData}
            onKeyDown={handleSearch}
            placeholder={"Search" + " " + headerData.placeholder + ".."}
          />
        </div>

        <div className="header__filter">
          <div className="header__filter__button">
            <p>Advanced Filter</p>
            <img
              className="header__filter-icon"
              onClick={handleFilterDiv}
              src={filterIcon}
              alt=""
            />
          </div>
          {showFilterDiv && (
            <>
              {(headerData.page === "User" ||
                headerData.page === "UserReport") && (
                <div className="header__filter__card">
                  <UserFilter
                    users={items}
                    setState={setState}
                    setFilter={setShowFilterDiv}
                  />
                </div>
              )}
              {headerData.page === "UserDetails" && (
                <div className="header__filter__card">
                  <ActivitiesFilter
                    activities={items}
                    setState={setState}
                    setFilter={setShowFilterDiv}
                  />
                </div>
              )}
              {headerData.page === "AdminList" && (
                <div className="header__filter__card">
                  <AdminFilter
                    admins={items}
                    setState={setState}
                    setFilter={setShowFilterDiv}
                  />
                  {/* <ActivitiesFilter
                    activities={items}
                    setState={setState}
                    setFilter={setShowFilterDiv}
                  /> */}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListHeader;
