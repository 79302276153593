import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import {
    handleCharSortLogic,
    handleNumberSortLogic,
} from "../../../Helper/Sorting/Sorting";

const HitTable = ({ activities, allActivities, setActivities }) => {
    const [sort, setSort] = useState(false);
    const [sortEvent, setSortEvent] = useState("");

    const handleSorting = (event, type) => {
        if (sortEvent !== "" && sortEvent !== event) {
            let data = [];
            if (type === "character") {
                data = handleCharSortLogic(allActivities, event, "ascending");
            } else if (type === "number") {
                data = handleNumberSortLogic(allActivities, event, "ascending");
            }

            setActivities(data);
            setSort(true);
        } else {
            if (sort === true) {
                let data = [];
                if (type === "character") {
                    data = handleCharSortLogic(allActivities, event, "descending");
                } else if (type === "number") {
                    data = handleNumberSortLogic(allActivities, event, "descending");
                }
                setActivities(data);
            } else {
                let data = [];
                if (type === "character") {
                    data = handleCharSortLogic(allActivities, event, "ascending");
                } else if (type === "number") {
                    data = handleNumberSortLogic(allActivities, event, "ascending");
                }
                setActivities(data);
            }
            setSort(!sort);
        }
        setSortEvent(event);
    };

    const formatDateTime = (date) => {
        const dateObj = new Date(date);
        const options = {
            dateStyle: 'short',
            timeStyle: 'short',
        }
        return dateObj.toLocaleString('en-US', options);
    }

    return (
        <table className="table">
            <thead>
            <tr className="table__head">
                <th className="table__segment">
                    <div className="table__head-element">
                        <p>DATE</p>
                    </div>
                </th>

                <th className="table__segment">
                    <div className="table__head-element">
                        <p>LEFT EYE GAIN(L)</p>
                    </div>
                </th>

                <th className="table__segment">
                    <div className="table__head-element">
                        <p>RIGHT EYE GAIN(L)</p>
                    </div>
                </th>

                <th className="table__segment">
                    <div className="table__head-element">
                        <p>LEFT EYE GAIN(R)</p>
                    </div>
                </th>

                <th className="table__segment">
                    <div className="table__head-element">
                        <p>RIGHT EYE GAIN(R)</p>
                    </div>
                </th>
            </tr>
            </thead>

            <tbody>
            {activities?.hit?.map((ac, i) => (
                <tr key={i} className="table__raw">
                    <td className="table__segment">{formatDateTime(ac?.createdAt)}</td>
                    <td className="table__segment">{ac?.leftTurn.leftEyeGain}</td>
                    <td className="table__segment">{ac?.leftTurn.rightEyeGain}</td>
                    <td className="table__segment">{ac?.rightTurn.leftEyeGain}</td>
                    <td className="table__segment">{ac?.rightTurn.rightEyeGain}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default HitTable;
