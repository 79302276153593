import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import Sidebar from "../../Sidebar/Sidebar";
import UserProfile from "../UserProfile/UserProfile";
import {axiosInstance} from "../../../Helper/ApiCall/ApiCall";
import GstTable from "../../Table/GstTable/GstTable";
import Pagination2 from "../../Pagination/Pagination2";
import HitTable from "../../Table/HitTable/HitTable";

const UserDetails = () => {
    const {id} = useParams();
    const [testResults, setTestResults] = useState({gst: [], hit: []});
    const [user, setUser] = useState({});
    // pagination
    const [currentPage, setCurrentPage] = useState({gst: 1, hit: 1});
    const [itemsPerPage, setItemsPerPage] = useState({gst: 5, hit: 5});
    const [displayedItemsOnTable, setDisplayedItemsOnTable] = useState({gst: [], hit: []});


    const navigate = useNavigate();
    const show = useSelector((state) => state.sidebar.show);

    const userProps = {
        title: "Gaze Stability Test",
        subTitleLeft: "Result Data",
        subTitleRight: "of this user",
        placeholder: "here",
        page: "UserDetails",
        userId: user._id,
    };

    const getUser = async () => {
        const urlString = `users/${id}`;
        const result = await axiosInstance.get(urlString);
        if (result?.data?.success === true) {
            const newUser = result?.data?.data;
            setUser(newUser);
            setTestResults({gst: newUser.gst, hit: newUser.hit});
        } else if (
            result?.response?.data?.message ===
            "Json Web Token is Expired, Try again "
        ) {
            navigate("/login");
        } else {
            setUser({});
            setTestResults({});
        }
    };

    // pagination starts
    const calculateTotalPages = (key) => {
        if (key === "gst") {
            return Math.ceil(testResults?.gst?.length / itemsPerPage.gst);
        } else if (key === "hit") {
            return Math.ceil(testResults?.hit?.length / itemsPerPage.hit);
        } else {
            console.error("Invalid key passed to calculateTotalPages");
            return 1;
        }
    }

    const calculateDisplayedItemsOnTable = (key) => {
        if (key !== "gst" && key !== "hit") {
            console.error("Invalid key passed to calculateDisplayedItemsOnTable");
            return [];
        }
        const indexOfLastItem = currentPage[key] * itemsPerPage[key];
        const indexOfFirstItem = indexOfLastItem - itemsPerPage[key];
        return testResults[key].slice(indexOfFirstItem, indexOfLastItem);
    }
    const handleNextPage = (key) => {
        if (key === "gst" || key === "hit") {
            setCurrentPage((prevPage) => ({...prevPage, [key]: prevPage[key] + 1}));
        }
    };

    const handlePrevPage = (key) => {
        setCurrentPage((prevPage) => ({...prevPage, [key]: prevPage[key] - 1}));
    };

    const handlePageClick = (key, pageNumber) => {
        setCurrentPage((prevPage) => ({...prevPage, [key]: pageNumber}));
    };

    const handleItemsPerPage = (key, itemsCount) => {
        setItemsPerPage(prevState => ({...prevState, [key]: itemsCount}));
        setCurrentPage(prevState => ({...prevState, [key]:1}));
    }
    // pagination end

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        const displayedGstItems = calculateDisplayedItemsOnTable("gst");
        const displayedHitItems = calculateDisplayedItemsOnTable("hit");
        console.log("displayedGstItems", displayedGstItems);
        setDisplayedItemsOnTable({gst: displayedGstItems, hit: displayedHitItems});
    }, [testResults, currentPage, itemsPerPage]);


    return (
        <div className="page">
            <Sidebar/>
            <UserProfile user={user} getUser={getUser}/>
            <div
                className={`page__container page__container--belowDiv ${
                    show ? "page__container--small" : "page__container--expand"
                }`}
            >
                {displayedItemsOnTable.gst.length > 0 ? (
                    <>
                        <div className="page__container__table-div">
                            <GstTable
                                activities={displayedItemsOnTable}
                                allActivities={displayedItemsOnTable}
                                setActivities={setDisplayedItemsOnTable}
                            />
                        </div>
                        <div className="page__container__pagination-div">
                            <Pagination2
                                currentPage={currentPage.gst}
                                totalPages={calculateTotalPages("gst")}
                                onNextPage={() => handleNextPage("gst")}
                                onPrevPage={() => {
                                    handlePrevPage("gst")
                                }}
                                itemsPerPage={itemsPerPage.gst}
                                onPageClick={handlePageClick.bind(undefined, 'gst')}
                                setPagePerItems={handleItemsPerPage.bind(undefined, 'gst')}
                            />
                        </div>
                    </>
                ) : (
                    <p className="page__container__no-match">No items found...</p>
                )}
            </div>

            <div
                className={`page__container page__container--belowDiv ${
                    show ? "page__container--small" : "page__container--expand"
                }`}
            >
                {displayedItemsOnTable.hit.length > 0 ? (
                    <>
                        <div className="page__container__table-div">
                            <HitTable
                                activities={displayedItemsOnTable}
                                allActivities={displayedItemsOnTable}
                                setActivities={setDisplayedItemsOnTable}
                            />
                        </div>
                        <div className="page__container__pagination-div">
                            <Pagination2
                                currentPage={currentPage.hit}
                                totalPages={calculateTotalPages("hit")}
                                onNextPage={() => handleNextPage("hit")}
                                onPrevPage={() => {
                                    handlePrevPage("hit")
                                }}
                                itemsPerPage={itemsPerPage.hit}
                                onPageClick={handlePageClick.bind(undefined, "hit")}
                                setPagePerItems={handleItemsPerPage.bind(undefined, "hit")}
                            />
                        </div>
                    </>
                ) : (
                    <p className="page__container__no-match">No items found...</p>
                )}
            </div>
        </div>
    );
};

export default UserDetails;
