import React, { useState, useEffect } from "react";
import checkVector from "../../../images/Vector.png";

const AdminFilter = ({ admins, setState, setFilter }) => {
  const [items, setItems] = useState([]);
  const [filterData, setFilterData] = useState({
    is_super_admin: "",
  });

  useEffect(() => {
    setItems(admins);
  }, []);

  const handleFilterData = (e, value) => {
    let key = e.target.name;
    if (key === undefined) {
      key = e.target.getAttribute("name");
    }
    setFilterData({
      ...filterData,
      [key]: value,
    });
  };

  const handleFilter = () => {
    let filteredValue = items;
    if (filterData.status) {
      filteredValue = filteredValue.filter(
        (item) => item.status === filterData.status
      );
    }
    if (filterData.is_super_admin !== "") {
      filteredValue = filteredValue.filter(
        (item) => item.is_super_admin === filterData.is_super_admin
      );
    }
    setState(filteredValue);
    setFilter(false);
    setFilterData({ status: "", is_super_admin: "" });
  };

  return (
    <div className="filter filter--medium">
      <div className="filter__section filter__section--checkbox">
        <label className="filter__section__label">Status</label>
        <div className="filter__section__actions">
          <div className="filter__check-element">
            <div
              className={`filter__checkbox ${
                filterData.status === "active"
                  ? "filter__checkbox--fill"
                  : "filter__checkbox--empty"
              }`}
              name="status"
              onClick={(e) => handleFilterData(e, "active")}
            >
              {filterData.status === "active" && (
                <img
                  className="filter__check-element__vector-icon"
                  src={checkVector}
                  alt=""
                />
              )}
            </div>
            <p>active</p>
          </div>
          <div className="filter__check-element">
            <div
              className={`filter__checkbox ${
                filterData.status === "pending"
                  ? "filter__checkbox--fill"
                  : "filter__checkbox--empty"
              }`}
              name="status"
              onClick={(e) => handleFilterData(e, "pending")}
            >
              {filterData.status === "pending" && (
                <img
                  className="filter__check-element__vector-icon"
                  src={checkVector}
                  alt=""
                />
              )}
            </div>
            <p>pending</p>
          </div>
        </div>
      </div>
      <div className="filter__section filter__section--admin-checkbox">
        <label className="filter__section__label">Role</label>
        <div className="filter__section__actions">
          <div className="filter__check-element">
            <div
              className={`filter__checkbox ${
                filterData.is_super_admin === true
                  ? "filter__checkbox--fill"
                  : "filter__checkbox--empty"
              }`}
              name="is_super_admin"
              onClick={(e) => handleFilterData(e, true)}
            >
              {filterData.is_super_admin === true && (
                <img
                  className="filter__check-element__vector-icon"
                  src={checkVector}
                  alt=""
                />
              )}
            </div>
            <p>super admin</p>
          </div>
          <div className="filter__check-element">
            <div
              className={`filter__checkbox ${
                filterData.is_super_admin === false
                  ? "filter__checkbox--fill"
                  : "filter__checkbox--empty"
              }`}
              name="is_super_admin"
              onClick={(e) => handleFilterData(e, false)}
            >
              {filterData.is_super_admin === false && (
                <img
                  className="filter__check-element__vector-icon"
                  src={checkVector}
                  alt=""
                />
              )}
            </div>
            <p>admin</p>
          </div>
        </div>
      </div>
      <button className="filter__submit-btn" onClick={handleFilter}>
        Filter
      </button>
    </div>
  );
};

export default AdminFilter;
