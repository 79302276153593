import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import appLogo from "../../../images/app_icon.png";
import "./Login.scss";
import { axiosInstance } from "../../../Helper/ApiCall/ApiCall";
import { validateEmail } from "../../../Helper/EmailValidator/EmailValidator";
import { setCurrentAdmin } from "../../../redux/adminSlice";

const Login = () => {
  const [error, setError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isNew, setIsNew] = useState(false);
  const [inputData, setInputData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
  });

  const location = useLocation();
  const origin = location.state?.from?.pathname || "/userList";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };

  const validate = (values, operation) => {
    const errors = {};
    if (operation === "signUp") {
      if (!values.name) {
        errors.name = "Name is required";
      }
      if (!values.phone) {
        errors.phone = "Phone is required";
      }
      if (!values.confirm_password) {
        errors.confirm_password = "Confirm Password is required";
      }
      if (values.password && values.confirm_password) {
        if (values.password !== values.confirm_password) {
          errors.confirm_password = "Password didn't match";
        }
      }
    }
    if (!values.email) {
      errors.email = "Email is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    if (values.email) {
      const validation = validateEmail(values.email);
      if (!validation) {
        errors.email = "Email is not valid";
      }
    }
    return errors;
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    const newErrors = validate(inputData, "signUp");
    setFormErrors(newErrors);
    const isNonEmpty = !Object.values(inputData).some(
      (x) => x === null || x === ""
    );
    if (isNonEmpty && Object.keys(newErrors).length === 0) {
      const createAdmin = async () => {
        const urlString = "auth/admin/signup";
        let result = await axiosInstance.post(urlString, inputData);
        if (result?.data?.success === true) {
          setError("");
          setIsNew(!isNew);
          setInputData({
            name: "",
            email: "",
            phone: "",
            password: "",
            confirm_password: "",
          });
        } else if (result?.response?.data?.success === false) {
          setError(result?.response?.data?.message);
        }
      };
      createAdmin();
    }
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    const { email, password } = inputData;
    const newInputData = {
      email,
      password,
    };
    const newErrors = validate(newInputData);
    setFormErrors(newErrors);
    const isNonEmpty = !Object.values(newInputData).some(
      (x) => x === null || x === ""
    );
    if (isNonEmpty && Object.keys(newErrors).length === 0) {
      const login = async () => {
        const { confirm_password, ...rest } = newInputData;
        const data = { ...rest };
        const urlString = "auth/admin/login";
        let result = await axiosInstance.post(urlString, data);
        if (result?.data?.success === true) {
          setError("");
          const user = result.data.data.user;
          const userId = user._id;
          const userName = user.name;
          const is_super_admin = user.is_super_admin;
          const status = user.status;
          const accessToken = result.data.data.tokens.access_token;
          const refreshToken = result.data.data.tokens.refresh_token;
          localStorage.setItem("is_super_admin", is_super_admin);
          localStorage.setItem("userId", userId);
          localStorage.setItem("userName", userName);
          localStorage.setItem("status", status);
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
          dispatch(setCurrentAdmin(user))
          if (user.status === "pending") {
            navigate("/admin/pending");
          } else {
            navigate(origin);
          }
        } else if (result?.response?.data?.success === false) {
          setError(result?.response?.data?.message);
        }
      };
      login();
    }
  };

  const handleSwitch = () => {
    setFormErrors({});
    setError("");
    setInputData({
      name: "",
      email: "",
      phone: "",
      password: "",
      confirm_password: "",
    });
    setIsNew(!isNew);
  };

  return (
    <div className="login-page">
      <div className="login-page__card">
        <div className="login-page__app-head">
          <img
            className="login-page__app-head__app-logo"
            src={appLogo}
            alt=""
          />
        </div>
        <div className="login-page__body">
          <p className="login-page__body__title">
            {isNew ? "Sign Up" : "Sign In"}
          </p>
          <p className="login-page__body__sub-title">
            Please fill in this form to {isNew ? "create an account" : "login"}!
          </p>
          {isNew ? (
            <form className="login-page__body__form" onSubmit={handleSignUp}>
              <div>
                {/* <label className="login-page__body__input-label">Name</label> */}
                <input
                  className="login-page__body__input-field"
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={inputData.name}
                  onInput={handleChange}
                />
                {formErrors.name && (
                  <p className="login-page__form-error">{formErrors.name}</p>
                )}
              </div>
              <div>
                {/* <label className="login-page__body__input-label">Email</label> */}
                <input
                  className="login-page__body__input-field"
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={inputData.email}
                  onInput={handleChange}
                />
                {formErrors.email && (
                  <p className="login-page__form-error">{formErrors.email}</p>
                )}
              </div>
              <div>
                {/* <label className="login-page__body__input-label">Phone</label> */}
                <input
                  className="login-page__body__input-field"
                  type="text"
                  name="phone"
                  placeholder="Phone"
                  value={inputData.phone}
                  onInput={handleChange}
                />
                {formErrors.phone && (
                  <p className="login-page__form-error">{formErrors.phone}</p>
                )}
              </div>
              <div>
                {/* <label className="login-page__body__input-label">Password</label> */}
                <input
                  className="login-page__body__input-field"
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={inputData.password}
                  onInput={handleChange}
                />
                {formErrors.password && (
                  <p className="login-page__form-error">
                    {formErrors.password}
                  </p>
                )}
              </div>
              <div>
                {/* <label className="login-page__body__input-label">Confirm Password</label> */}
                <input
                  className="login-page__body__input-field"
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm Password"
                  value={inputData.confirm_password}
                  onInput={handleChange}
                />
                {formErrors.confirm_password && (
                  <p className="login-page__form-error">
                    {formErrors.confirm_password}
                  </p>
                )}
              </div>
              <button className="login-page__btn" type="submit">
                Sign Up
              </button>
            </form>
          ) : (
            <form className="login-page__body__form" onSubmit={handleSignIn}>
              <div>
                {/* <label className="login-page__body__input-label">Email</label> */}
                <input
                  className="login-page__body__input-field"
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={inputData.email}
                  onInput={handleChange}
                />
                {formErrors.email && (
                  <p className="login-page__form-error">{formErrors.email}</p>
                )}
              </div>
              <div>
                {/* <label className="login-page__body__input-label">Password</label> */}
                <input
                  className="login-page__body__input-field"
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={inputData.password}
                  onInput={handleChange}
                />
                {formErrors.password && (
                  <p className="login-page__form-error">
                    {formErrors.password}
                  </p>
                )}
              </div>
              <button className="login-page__btn" type="submit">
                Sign In
              </button>
            </form>
          )}
        </div>
        {error && <p className="login-page__card__error-message">{error}</p>}
      </div>
      <p className="login-page__option">
        {isNew ? "Already have an account?" : "Don't have an account?"}{" "}
        <a onClick={handleSwitch} className="login-page__option__btn">
          {isNew ? "Sign in" : "Sign Up"}
        </a>
      </p>
    </div>
  );
};

export default Login;
