export const handleCharSortLogic = (items, event, operator) => {
  const data = [...items].sort((a, b) =>
    (
      operator === "ascending"
        ? a[event].toLowerCase() < b[event].toLowerCase()
        : a[event].toLowerCase() > b[event].toLowerCase()
    )
      ? -1
      : 1
  );
  return data;
};

export const handleNumberSortLogic = (items, event, operator) => {
  const data = [...items].sort((a, b) =>
    operator === "ascending" && event in a && event in b? a[event] - b[event] : b[event] - a[event]
  );
  return data;
};
