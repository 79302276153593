import pageDropIcon from "../../images/pageDrop.png";
import React, {useState} from "react";
import leftArrow from "../../images/leftArrow.png";
import rightArrow from "../../images/rightArrow.png";

const Pagination2 = ({
                         currentPage,
                         totalPages,
                         itemsPerPage,
                         onNextPage,
                         onPrevPage,
                         onPageClick,
                         setPagePerItems,
                     }) => {

    const pageNumbers = Array.from({length: totalPages}, (_, i) => i + 1);
    const [showDropDown, setShowDropDown] = useState(false);


    return (
        <div className="pagination">
            <div className="pagination__left">
                <p className="pagination__left-text">Raws per page:</p>
                <div className="pagination__dropDown" onClick={() => setShowDropDown(!showDropDown)}>
                    {itemsPerPage}
                    <img
                        className="pagination__drop-icon"
                        src={pageDropIcon}
                        alt=""
                    />
                    {showDropDown && (
                        <div className="pagination__dropDown__count-container">
                            <div className="pagination__page-count">
                                <li
                                    className="pagination__count-list"
                                    onClick={() => setPagePerItems(5)}
                                >
                                    5
                                </li>
                                <li
                                    className="pagination__count-list"
                                    onClick={() => setPagePerItems(10)}
                                >
                                    10
                                </li>
                                <li
                                    className="pagination__count-list"
                                    onClick={() => setPagePerItems(15)}
                                >
                                    15
                                </li>
                                <li
                                    className="pagination__count-list"
                                    onClick={() => setPagePerItems(20)}
                                >
                                    20
                                </li>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="pagination__right">

                <button className="pagination__arrow" onClick={onPrevPage} disabled={currentPage === 1}>
                    <img className="pagination__arrow__img" src={leftArrow} alt="left arrow icon" />
                </button>

                {pageNumbers.map((pageNumber) => (
                    <p  key={pageNumber}
                        className={`pagination__page-number ${currentPage === pageNumber && "pagination__page-number__active"}`}
                        onClick={() => onPageClick(pageNumber)}
                    >
                        {pageNumber}
                    </p>
                ))}

                <button
                    className="pagination__arrow pagination__arrow--last"
                    onClick={onNextPage}
                    disabled={currentPage === totalPages}
                >
                    <img className="pagination__arrow__img" src={rightArrow} alt="" />
                </button>
            </div>
        </div>
    );
};

export default Pagination2;
