import React, { useEffect, useState } from "react";
import "./UserFilter.scss";
import checkVector from "../../../images/Vector.png";

const UserFilter = ({ users, setState, setFilter }) => {
  const [items, setItems] = useState([]);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setItems(users);
  }, []);

  const handleFilterData = (e, value) => {
    let key = e.target.name;
    if (key === undefined) {
      key = e.target.getAttribute("name");
    }
    setFilterData({
      ...filterData,
      [key]: value,
    });
  };

  const handleFilter = () => {
    const stingFormation = (text) => {
      if (text.includes("-")) {
        return text.split("-");
      } else {
        return text.slice(0, -1);
      }
    };
    let filteredValue = items;
    const filterGenerator = (text) => {
      const newRes = filteredValue.filter((item) =>
        filterData[text].includes("-")
          ? item[text] >= stingFormation(filterData[text])[0] &&
            item[text] < stingFormation(filterData[text])[1]
          : item[text] >= stingFormation(filterData[text])
      );
      return newRes;
    };
    if (filterData.status) {
      filteredValue = filteredValue.filter(
        (item) => item.status === filterData.status
      );
    }
    if (filterData.session_count) {
      filteredValue = filterGenerator("session_count");
    }
    setState(filteredValue);
    setFilter(false);
    setFilterData({});
  };
  return (
    <div className="filter filter--medium">
      <div className="filter__section filter__section--checkbox">
        <label className="filter__section__label">Status</label>
        <div className="filter__section__actions">
          <div className="filter__check-element">
            <div
              className={`filter__checkbox ${
                filterData.status === "active"
                  ? "filter__checkbox--fill"
                  : "filter__checkbox--empty"
              }`}
              name="status"
              onClick={(e) => handleFilterData(e, "active")}
            >
              {filterData.status === "active" && (
                <img
                  className="filter__check-element__vector-icon"
                  src={checkVector}
                  alt=""
                />
              )}
            </div>
            <p>active</p>
          </div>
          <div className="filter__check-element">
            <div
              className={`filter__checkbox ${
                filterData.status === "inactive"
                  ? "filter__checkbox--fill"
                  : "filter__checkbox--empty"
              }`}
              name="status"
              onClick={(e) => handleFilterData(e, "inactive")}
            >
              {filterData.status === "inactive" && (
                <img
                  className="filter__check-element__vector-icon"
                  src={checkVector}
                  alt=""
                />
              )}
            </div>
            <p>inactive</p>
          </div>
        </div>
      </div>
      <div className="filter__section filter__section--activity-count">
        <label className="filter__section__label">Session Count</label>
        <div className="filter__section__actions">
          <button
            className={`filter__element-btn ${
              filterData.session_count === "0-10" && "filter__element-btn--fill"
            }`}
            name="session_count"
            onClick={(e) => handleFilterData(e, "0-10")}
          >
            0 - 10
          </button>
          <button
            className={`filter__element-btn ${
              filterData.session_count === "10-20" &&
              "filter__element-btn--fill"
            }`}
            name="session_count"
            onClick={(e) => handleFilterData(e, "10-20")}
          >
            10 - 20
          </button>
          <button
            className={`filter__element-btn ${
              filterData.session_count === "20+" && "filter__element-btn--fill"
            }`}
            name="session_count"
            onClick={(e) => handleFilterData(e, "20+")}
          >
            20 times +
          </button>
        </div>
      </div>
      <button className="filter__submit-btn" onClick={handleFilter}>
        Filter
      </button>
    </div>
  );
};

export default UserFilter;
