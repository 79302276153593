import React, { useEffect, useState } from "react";
import moment from "moment";
import DatePicker from "react-date-picker";

const ActivitiesFilter = ({ activities, setState, setFilter }) => {
  const [items, setItems] = useState([]);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setItems(activities);
  }, []);

  const handleDateFilterData = (e) => {
    setFilterData({
      ...filterData,
      date: e,
    });
  };

  const handleFilter = () => {
    let filteredValue = items;
    if (filterData.date) {
      filteredValue = filteredValue.filter(
        (item) =>
          moment(item.date).format("YYYY-MM-DD") ===
          moment(filterData.date).format("YYYY-MM-DD")
      );
    }
    setState(filteredValue);
    setFilter(false);
    setFilterData({});
  };
  return (
    <div className="filter">
      <div className="filter__section">
        <label className="filter__section__label">Date</label>
        <div className="filter__section__actions">
          <DatePicker
            className={`react-date-picker ${
              filterData.date && "react-date-picker--active"
            }`}
            yearPlaceholder="yyyy"
            monthPlaceholder="mm"
            dayPlaceholder="dd"
            formatMonth={"mm"}
            onChange={handleDateFilterData}
            value={filterData.date}
            dateFormate="YYYY-MM-DD"
          />
        </div>
      </div>
      <button className="filter__submit-btn" onClick={handleFilter}>
        Filter
      </button>
    </div>
  );
};

export default ActivitiesFilter;
