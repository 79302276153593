import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import editBtnIcon from "../../../images/editProfileDoneIcon.svg";
import AddPageHeader from "../../Header/AddPageHeader/AddPageHeader";
import Sidebar from "../../Sidebar/Sidebar";
import {
  activePageSet,
  backToInitialSellSpan,
  backToInitialSubPage,
} from "../../../redux/sidebarSlice";
import { axiosInstance } from "../../../Helper/ApiCall/ApiCall";
import { setCurrentAdmin } from "../../../redux/adminSlice";

const UpdateAdminProfile = () => {
  const userId = localStorage.getItem("userId");
  const [defaultUser, setDefaultUser] = useState({});
  const [inputData, setInputData] = useState({
    name: "",
    phone: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const dispatch = useDispatch();
  const show = useSelector((state) => state.sidebar.show);
  const currentAdmin = useSelector((state) => state.admin.currentAdmin);

  const navigate = useNavigate();
  const profileProps = {
    title: "Update Profile",
    subTitleLeft: "Admin",
    subTitleRight: "profile update page",
  };

  const getAdmin = async (id) => {
    const urlString = `admins/${id}`;
    const result = await axiosInstance.get(urlString);
    if (result?.data?.success === true) {
      dispatch(setCurrentAdmin(result?.data?.data));
    }else if(result?.response?.data?.message==='Json Web Token is Expired, Try again '){
      navigate("/login");
    } else {
      dispatch(setCurrentAdmin({}));
    }
  };

  useEffect(() => {
    dispatch(activePageSet(1));
    dispatch(backToInitialSellSpan());
    dispatch(backToInitialSubPage());
  }, [userId]);

  useEffect(() => {
    if (!currentAdmin.name) {
      getAdmin(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (currentAdmin.name) {
      setDefaultUser(currentAdmin);
      const newDefaultUser = {
        name: currentAdmin?.name,
        phone: currentAdmin?.phone,
      };
      setInputData(newDefaultUser);
    }
  }, [currentAdmin]);

  const handleChange = (e) => {
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.phone) {
      errors.phone = "Phone number is required";
    }
    return errors;
  };

  const updateProfile = (e) => {
    e.preventDefault();
    let newUser = {};
    const newErrors = validate(inputData);
    setFormErrors(newErrors);
    const isNonEmpty = !Object.values(inputData).some(
      (x) => x === null || x === ""
    );

    if (isNonEmpty && Object.keys(newErrors).length === 0) {
      if (inputData.name !== defaultUser.name) {
        newUser.name = inputData.name;
      }
      if (inputData.phone !== defaultUser.phone) {
        newUser.phone = inputData.phone;
      }
      if (Object.keys(newUser).length !== 0) {
        const updateAdmin = async () => {
          const urlString = `admins/${userId}`;
          const result = await axiosInstance.put(urlString, newUser);
          if (result?.data?.success === true) {
            dispatch(setCurrentAdmin(result.data.data));
            navigate("/adminProfile");
          } else if (
            result?.response?.data.message === "Duplicate phone Entered"
          ) {
            setFormErrors({
              ...formErrors,
              phone: result?.response?.data?.message,
            });
          }
          else if(result?.response?.data?.message==='Json Web Token is Expired, Try again '){
            navigate("/login");
          }
        };
        updateAdmin();
      }
    }
  };

  return (
    <div className="page">
      <Sidebar />

      <div
        className={`page__container ${
          show ? "page__container--small" : "page__container--expand"
        }`}
      >
        <div className="page__container__header">
          <AddPageHeader headerData={profileProps} />
        </div>
        <div className="page__profileDiv">
          <div className="page__profile-container">
            <div className="page__profile-edit">
              <form onSubmit={updateProfile} id="myForm">
                <div>
                  <label className="page__container__input-label">Name</label>
                  <input
                    className="page__input-field"
                    type="text"
                    name="name"
                    value={inputData?.name}
                    onInput={handleChange}
                  />
                  {formErrors.name && (
                    <p className="page__form-error">{formErrors.name}</p>
                  )}
                </div>
                <div>
                  <label className="page__container__input-label">Email</label>
                  <input
                    className="page__input-field"
                    type="text"
                    name="email"
                    readOnly={true}
                    defaultValue={defaultUser?.email}
                  />
                </div>
                <div>
                  <label className="page__container__input-label">
                    Profile Privilege
                  </label>
                  <input
                    className="page__input-field"
                    type="text"
                    name="role"
                    value={
                      defaultUser?.is_super_admin
                        ? "Super Administrator"
                        : "Administrator"
                    }
                    readOnly={true}
                  />
                </div>
                <div>
                  <label className="page__container__input-label">
                    Phone Number
                  </label>
                  <input
                    className="page__input-field"
                    type="text"
                    name="phone"
                    defaultValue={inputData.phone}
                    onInput={handleChange}
                  />
                  {formErrors.phone && (
                    <p className="page__form-error">{formErrors.phone}</p>
                  )}
                </div>
                <button
                  className="page__submitBtn page__submitBtn--fill"
                  type="submit"
                >
                  <img
                    className="page__update-profileBtnImg"
                    src={editBtnIcon}
                    alt=""
                  />
                  Update Done
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateAdminProfile;
