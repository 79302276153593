import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as BiIcons from "react-icons/bi";
import * as IoIcons from "react-icons/io";
import AddPageHeader from "../../Header/AddPageHeader/AddPageHeader";
import Sidebar from "../../Sidebar/Sidebar";
import {
  activePageSet,
  activeSubPageSet,
  sellSpanSet,
} from "../../../redux/sidebarSlice";
import { validateEmail } from "../../../Helper/EmailValidator/EmailValidator";
import { axiosInstance } from "../../../Helper/ApiCall/ApiCall";
import { setAllUsers } from "../../../redux/userSlice";
import moment from "moment";

const AddUser = () => {
  const [inputData, setInputData] = useState({
    name: "",
    email: "",
  });
  const [exit, setExit] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const show = useSelector((state) => state.sidebar.show);

  const userProps = {
    title: "Add User",
    subTitleLeft: "New User",
    subTitleRight: "insert new entry",
  };

  useEffect(() => {
    dispatch(activePageSet(0));
    dispatch(activeSubPageSet("0.1"));
  }, []);
  useEffect(()=>{
    if(show){
      dispatch(sellSpanSet(1));
    }
  },[show])

  const handleChange = (e) => {
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.email) {
      errors.email = "Email is required";
    }
    if (values.email) {
      const validation = validateEmail(values.email);
      if (!validation) {
        errors.email = "Email is not valid";
      }
    }
    return errors;
  };

  const createUser = (e) => {
    e.preventDefault();
    const newErrors = validate(inputData);
    setFormErrors(newErrors);
    const isNonEmpty = !Object.values(inputData).some(
      (x) => x === null || x === ""
    );
    if (isNonEmpty && Object.keys(newErrors).length === 0) {
      const addUser = async () => {
        const urlString = "users";
        let result = await axiosInstance.post(urlString, inputData);
        if (result?.data?.success === true) {
          if (exit) {
            navigate("/userList");
          }else if(result?.response?.data?.message==='Json Web Token is Expired, Try again '){
            navigate("/login");
          } else {
            setInputData({
              name: "",
              email: "",
            });
          }
          getUsers()
        }
      };
      addUser();
    }
  };

  const getUsers = async () => {
    const urlString = "users";
    let result = await axiosInstance.get(urlString);
    if (result?.data?.success === true) {
      const newUsers = result?.data?.data;
      newUsers.map((user, i) => {
        user.serial = i + 1;
        user.session_count = user?.velocities.length || 0;
        user?.velocities?.map((velocity, i) => {
          velocity.date = moment(velocity.createdAt).format("YYYY-MM-DD");
        });
      });
      dispatch(setAllUsers(newUsers))
    }else if(result?.response?.data?.message==='Json Web Token is Expired, Try again '){
      navigate("/login");
    } else {
      dispatch(setAllUsers([]))
    }
  };

  return (
    <div className="page">
      <Sidebar />
      <div
        className={`page__container ${
          show ? "page__container--small" : "page__container--expand"
        }`}
      >
        <div className="page__container__header">
          <AddPageHeader headerData={userProps} />
        </div>
        <form className="page__data-form" onSubmit={createUser}>
          <div>
            <label className="page__container__input-label">Name</label>
            <input
              className="page__input-field"
              type="text"
              name="name"
              value={inputData.name}
              onInput={handleChange}
            />
            {formErrors.name && (
              <p className="page__form-error">{formErrors.name}</p>
            )}
          </div>
          <div>
            <label className="page__container__input-label">Email</label>
            <input
              className="page__input-field"
              type="text"
              name="email"
              value={inputData.email}
              onInput={handleChange}
            />
            {formErrors.email && (
              <p className="page__form-error">{formErrors.email}</p>
            )}
          </div>
          <div className="page__form-btnDiv">
            <button
              className="page__submitBtn page__submitBtn--fill"
              type="submit"
              onClick={() => {
                setExit(false);
              }}
            >
              <span className="page__formBtn-icon">
                <BiIcons.BiLayerPlus />
              </span>
              Save and Continue
            </button>

            <button
              className="page__submitBtn page__submitBtn--transparent"
              onClick={() => setExit(true)}
              type="submit"
            >
              <>
                <span className="page__formBtn-icon">
                  <IoIcons.IoMdExit />
                </span>
                save and Exit
              </>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUser;
