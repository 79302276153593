import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import selectVector from "../../../images/Vector.png";
import * as FaIcons from "react-icons/fa";
import "./UsersTable.scss";
import ConfirmDeleteCard from "../../Modal/ConfirmDeleteCard/ConfirmDeleteCard";
import { selectUsers } from "../../../redux/userSlice";
import {
  handleCharSortLogic,
  handleNumberSortLogic,
} from "../../../Helper/Sorting/Sorting";
import { axiosInstance } from "../../../Helper/ApiCall/ApiCall";

const UsersTable = ({
  users,
  selectOption,
  page,
  getUsers,
  allUsers,
  setUsers,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.user.selectedUsers);
  const [sort, setSort] = useState(false);
  const [sortEvent, setSortEvent] = useState("");

  const handleSelect = (id) => {
    if (!selected.includes(id)) {
      const newData = [...selected];
      newData.push(id);
      dispatch(selectUsers(newData));
    } else {
      const newData = [...selected];
      const index = newData.indexOf(id);
      if (index > -1) {
        newData.splice(index, 1);
      }
      dispatch(selectUsers(newData));
    }
  };

  const handleSelectAll = () => {
    const newUsers = [];
    users.map((user) => newUsers.push(user._id));
    if (newUsers.every((user) => selected.includes(user))) {
      const myArray = selected.filter((el) => !newUsers.includes(el));
      dispatch(selectUsers(myArray));
    } else {
      const newArr = [...selected];
      users.map((user) => {
        if (!newArr.includes(user._id)) {
          newArr.push(user._id);
        }
      });
      dispatch(selectUsers(newArr));
    }
  };

  const handleDetails = (id) => {
    navigate(`/user/${id}`);
  };

  const [deleteItem, setDeleteItem] = useState("");
  const handleDeleteData = async (id) => {
    setDeleteItem(id);
  };

  const handleDelete = () => {
    const deleteStudent = async () => {
      const urlString = `users/${deleteItem}`;
      const result = await axiosInstance.delete(urlString);
      if (result?.status === 204) {
        getUsers();
        setDeleteItem("");
      }
      else if(result?.response?.data?.message==='Json Web Token is Expired, Try again '){
        navigate("/login");
      }
    };
    deleteStudent();
  };

  const handleSorting = (event, type) => {
    if (sortEvent !== "" && sortEvent !== event) {
      let data = [];
      if (type === "character") {
        data = handleCharSortLogic(allUsers, event, "ascending");
      } else if (type === "number") {
        data = handleNumberSortLogic(allUsers, event, "ascending");
      } else if (type === "serial") {
        data = handleNumberSortLogic(allUsers, event, "descending");
      }
      setUsers(data);
      setSort(true);
    } else {
      if (sort === true) {
        let data = [];
        if (type === "character") {
          data = handleCharSortLogic(allUsers, event, "descending");
        } else if (type === "number") {
          data = handleNumberSortLogic(allUsers, event, "descending");
        } else if (type === "serial") {
          data = handleNumberSortLogic(allUsers, event, "ascending");
        }
        setUsers(data);
      } else {
        let data = [];
        if (type === "character") {
          data = handleCharSortLogic(allUsers, event, "ascending");
        } else if (type === "number") {
          data = handleNumberSortLogic(allUsers, event, "ascending");
        } else if (type === "serial") {
          data = handleNumberSortLogic(allUsers, event, "descending");
        }
        setUsers(data);
      }
      setSort(!sort);
    }
    setSortEvent(event);
  };

  return (
    <table className="table">
      <thead>
        <tr className="table__head">
          {selectOption && (
            <th className="table__segment">
              <div
                onClick={handleSelectAll}
                className={`table__select-box ${
                  users.every((user) => selected.includes(user._id)) &&
                  "table__select-box--fill"
                }`}
              >
                {users.every((user) => selected.includes(user._id)) && (
                  <img src={selectVector} alt="" />
                )}
              </div>
            </th>
          )}

          <th className="table__segment">
            <div className="table__head-element">
              <p>SERIAL</p>
              <div
                className="table__sort-arrow"
                onClick={() => handleSorting("serial", "serial")}
              >
                <FaIcons.FaAngleUp
                  className={`${
                    sortEvent === "serial" &&
                    sort === false &&
                    "table__sort-arrow__bold"
                  }`}
                />
                <FaIcons.FaAngleDown
                  className={`${
                    sortEvent === "serial" &&
                    sort === true &&
                    "table__sort-arrow__bold"
                  }`}
                />
              </div>
            </div>
          </th>

          <th className="table__segment">
            <div className="table__head-element">
              <p>NAME</p>
              <div
                className="table__sort-arrow"
                onClick={() => handleSorting("name", "character")}
              >
                <FaIcons.FaAngleUp
                  className={`${
                    sortEvent === "name" &&
                    sort === true &&
                    "table__sort-arrow__bold"
                  }`}
                />
                <FaIcons.FaAngleDown
                  className={`${
                    sortEvent === "name" &&
                    sort === false &&
                    "table__sort-arrow__bold"
                  }`}
                />
              </div>
            </div>
          </th>

          <th className="table__segment">
            <div className="table__head-element">
              <p>EMAIL</p>
              <div
                className="table__sort-arrow"
                onClick={() => handleSorting("email", "character")}
              >
                <FaIcons.FaAngleUp
                  className={`${
                    sortEvent === "email" &&
                    sort === true &&
                    "table__sort-arrow__bold"
                  }`}
                />
                <FaIcons.FaAngleDown
                  className={`${
                    sortEvent === "email" &&
                    sort === false &&
                    "table__sort-arrow__bold"
                  }`}
                />
              </div>
            </div>
          </th>

          <th className="table__segment">
            <div className="table__head-element">
              <p>STATUS</p>
              <div
                className="table__sort-arrow"
                onClick={() => handleSorting("status", "character")}
              >
                <FaIcons.FaAngleUp
                  className={`${
                    sortEvent === "status" &&
                    sort === true &&
                    "table__sort-arrow__bold"
                  }`}
                />
                <FaIcons.FaAngleDown
                  className={`${
                    sortEvent === "status" &&
                    sort === false &&
                    "table__sort-arrow__bold"
                  }`}
                />
              </div>
            </div>
          </th>

          <th className="table__segment">
            <div className="table__head-element">
              <p>GST SESSION</p>
              <div
                className="table__sort-arrow"
                onClick={() => handleSorting("gst_session_count", "number")}
              >
                <FaIcons.FaAngleUp
                  className={`${
                    sortEvent === "gst_session_count" &&
                    sort === true &&
                    "table__sort-arrow__bold"
                  }`}
                />
                <FaIcons.FaAngleDown
                  className={`${
                    sortEvent === "gst_session_count" &&
                    sort === false &&
                    "table__sort-arrow__bold"
                  }`}
                />
              </div>
            </div>
          </th>

          <th className="table__segment">
            <div className="table__head-element">
              <p>HIT Session</p>
              <div
                  className="table__sort-arrow"
                  onClick={() => handleSorting("hit_session_count", "number")}
              >
                <FaIcons.FaAngleUp
                    className={`${
                        sortEvent === "hit_session_count" &&
                        sort === true &&
                        "table__sort-arrow__bold"
                    }`}
                />
                <FaIcons.FaAngleDown
                    className={`${
                        sortEvent === "hit_session_count" &&
                        sort === false &&
                        "table__sort-arrow__bold"
                    }`}
                />
              </div>
            </div>
          </th>

          <th className="table__segment">
            <div className="table__head-element">LOGIN CODE</div>
          </th>
          <th className="table__segment--action">ACTION</th>
        </tr>
      </thead>

      <tbody>
        {users?.map((user, i) => (
          <tr key={i} className="table__raw">
            {selectOption && (
              <td className="table__segment">
                <div
                  onClick={() => handleSelect(user._id)}
                  className={`table__select-box ${
                    selected.includes(user._id) && "table__select-box--fill"
                  }`}
                >
                  {selected.includes(user._id) && (
                    <img src={selectVector} alt="" />
                  )}
                </div>
              </td>
            )}
            <td className="table__segment">{user?.serial}</td>
            <td className="table__segment">{user?.name}</td>
            <td className="table__segment">{user?.email}</td>
            <td className="table__segment">{user?.status}</td>
            <td className="table__segment">{user?.gst.length}</td>
            <td className="table__segment">{user?.hit.length}</td>
            <td className="table__segment">{user?.login_code}</td>
            <td className="table__segment--action">
              <div
                className={`${
                  page === "UserList"
                    ? "table__btnDiv"
                    : "table__btnDiv--single"
                }`}
              >
                {page === "UserList" && (
                  <button
                    onClick={() => {
                      handleDeleteData(user._id);
                    }}
                    className="table__btnDiv__delete-btn"
                  >
                    Delete
                  </button>
                )}
                <button
                  className="table__btnDiv__details-btn"
                  onClick={() => handleDetails(user._id)}
                >
                  Details
                </button>
              </div>
              {deleteItem === user._id && (
                <div className="table__confirm-card">
                  <ConfirmDeleteCard
                    handleDelete={handleDelete}
                    setDeleteItem={setDeleteItem}
                  />
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UsersTable;
