export const userSearch = (dbUsers, searchText) => {
  const matchUsers = dbUsers.filter((value) => {
    return (
      value.name.toLowerCase().includes(searchText.toLowerCase()) ||
      value.email.toLowerCase().includes(searchText.toLowerCase()) ||
      value.login_code.toLowerCase().includes(searchText.toLowerCase())
    );
  });
  return matchUsers;
};
