import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Pagination from "../../Pagination/Pagination";
import Sidebar from "../../Sidebar/Sidebar";
import ListHeader from "../../Header/ListHeader/ListHeader";
import UsersTable from "../../Table/UsersTable/UsersTable";
import {
  activePageSet,
  backToInitialSellSpan,
  backToInitialSubPage,
} from "../../../redux/sidebarSlice";
import { backToInitialCurrentItems } from "../../../redux/paginationSlice";
import { backToInitialSelectedUsers, backToInitialUserSearch, setAllUsers } from "../../../redux/userSlice";
import { userSearch } from "../../../Helper/UserSearch/UserSearch";
import { axiosInstance } from "../../../Helper/ApiCall/ApiCall";
import { useNavigate } from "react-router-dom";

const UserListReport = () => {
  const [users, setUsers] = useState([]);
  const [dbUsers, setDbUsers] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const show = useSelector((state) => state.sidebar.show);
  const searchText = useSelector((state) => state.user.userSearchText);
  const allUsers = useSelector((state) => state.user.allUsers);
  const reduxCurrentItems = useSelector(
    (state) => state.pagination.currentItems
  );

  const userProps = {
    title: "Users",
    subTitleLeft: "Current Data",
    subTitleRight: `of ${users?.length} users`,
    placeholder: "by name,email & code",
    page: "UserReport",
  };

  const getUsers = async () => {
    const urlString = "users";
    let result = await axiosInstance.get(urlString);
    if (result?.data?.success === true) {
      const newUsers = result?.data?.data;
      newUsers.map((user, i) => {
        user.serial = i + 1;
        user.session_count = user?.velocities.length || 0;
        user?.velocities?.map((velocity, i) => {
          velocity.date = moment(velocity.createdAt).format("YYYY-MM-DD");
        });
      });
      dispatch(setAllUsers(newUsers))
    }else if(result?.response?.data?.message==='Json Web Token is Expired, Try again '){
      navigate("/login");
    } else {
      dispatch(setAllUsers([]))
    }
  };

  useEffect(() => {
    if(allUsers.length<=0){
      getUsers()
    }
  }, []);


  useEffect(() => {
    if(allUsers.length>0){
      setUsers(allUsers);
      setDbUsers(allUsers);
    }
  }, [allUsers]);

  useEffect(() => {
    dispatch(backToInitialUserSearch());
    dispatch(backToInitialCurrentItems());
    dispatch(backToInitialSelectedUsers());
    dispatch(activePageSet(2));
    dispatch(backToInitialSellSpan());
    dispatch(backToInitialSubPage());
  }, []);

  useEffect(() => {
    if (searchText !== "") {
      const newUsers = userSearch(dbUsers, searchText);
      setUsers(newUsers);
    } else {
      setUsers(dbUsers);
    }
  }, [searchText, dbUsers]);

  useEffect(() => {
    setCurrentUsers(reduxCurrentItems);
  }, [reduxCurrentItems]);

  return (
    <div className="page">
      <Sidebar />
      <div
        className={`page__container ${
          show ? "page__container--small" : "page__container--expand"
        }`}
      >
        <div className="page__container__header">
          <ListHeader
            headerData={userProps}
            items={dbUsers}
            setState={setUsers}
          />
        </div>
        {users.length > 0 ? (
          <div className="page__container__table-div">
            <UsersTable
              selectOption={true}
              users={currentUsers}
              allUsers={users}
              setUsers={setUsers}
            />
          </div>
        ) : (
          <p className="page__container__no-match">No items found..</p>
        )}
        {users.length > 0 && (
          <div className="page__container__pagination-div">
            <Pagination items={users} />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserListReport;
