import React from "react";

const ConfirmStatusChangeCard = ({ handleStatus, setStatusData }) => {
  const changeEvent = () => {
    handleStatus();
  };
  const cancelEvent = () => {
    setStatusData("");
  };
  return (
    <div className="delete-card">
      <p className="delete-card__title">Confirm Change ?</p>
      <div className="delete-card__btn-div">
        <div className="delete-card__btn-div__changeBtn" onClick={changeEvent}>
          Change
        </div>
        <div className="delete-card__btn-div__cancelBtn" onClick={cancelEvent}>
          Cancel
        </div>
      </div>
    </div>
  );
};

export default ConfirmStatusChangeCard;
