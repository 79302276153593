import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

export const PrivateRoute = ({ children }) => {
  let accessToken = localStorage.getItem("accessToken");
  let refreshToken = localStorage.getItem("refreshToken");
  const location = useLocation();

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  const decodedJwt = parseJwt(refreshToken);
  useEffect(() => {
    if (decodedJwt.exp * 1000 < Date.now()) {
      window.localStorage.clear();
    }
  }, [location]);
  return accessToken !== null && decodedJwt?.exp * 1000 >= Date.now() ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};
