import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import pageDropIcon from "../../images/pageDrop.png";
import leftArrow from "../../images/leftArrow.png";
import rightArrow from "../../images/rightArrow.png";
import "./Pagination.scss";
import {
  setActivitiesItems,
  setReduxCurrentItems,
} from "../../redux/paginationSlice";

const Pagination = ({ items, page, studentsPerPage }) => {
  const [itemsPerPage, setItemsPerPage] = useState(studentsPerPage || 5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCountDiv, setPageCountDiv] = useState(false);
  const [pageCounter, setPageCounter] = useState(0);
  const dispatch = useDispatch();

  const calculator = () => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const ci = items.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(ci);
    setTotalPage(Math.ceil(items.length / itemsPerPage));
  };

  let pages = [];
  for (let i = 1; i <= totalPage; i++) {
    pages.push(i);
  }


  useEffect(() => {
    calculator();
    if (page === "UserActivities") {
      dispatch(setActivitiesItems(currentItems));
    } else {
      dispatch(setReduxCurrentItems(currentItems));
    }
  }, [currentItems]);

  useEffect(() => {
    setCurrentPage(1);
  }, [items]);

  const handlePageCount = () => {
    if (pageCounter === 0) {
      setPageCountDiv(true);
      setPageCounter(pageCounter + 1);
    } else {
      setPageCountDiv(false);
      setPageCounter(pageCounter - 1);
    }
  };

  const handleBackWord = () => {
    if (startPage > 1) {
      setStartPage(startPage - 1);
    }
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleForWord = () => {
    if (startPage < totalPage) {
      if (currentPage !== totalPage) {
        setCurrentPage(currentPage + 1);
      }
      if (totalPage - startPage >= 7) {
        setStartPage(startPage + 1);
      }
    }
  };

  const handlePagePerItems = (number) => {
    setItemsPerPage(number);
    setTotalPage(Math.ceil(items.length / number));
    setCurrentPage(1);
    setPageCountDiv(false);
    setPageCounter(0);
  };

  return (
    <div className="pagination">
      <div className="pagination__left">
        {!studentsPerPage && (
          <>
            <p className="pagination__left-text">Raws per page:</p>
            <div className="pagination__dropDown">
              {itemsPerPage}
              <img
                className="pagination__drop-icon"
                onClick={handlePageCount}
                src={pageDropIcon}
                alt=""
              />
              {pageCountDiv && (
                <div className="pagination__dropDown__count-container">
                  <div className="pagination__page-count">
                    <li
                      className="pagination__count-list"
                      onClick={() => handlePagePerItems(5)}
                    >
                      5
                    </li>
                    <li
                      className="pagination__count-list"
                      onClick={() => handlePagePerItems(10)}
                    >
                      10
                    </li>
                    <li
                      className="pagination__count-list"
                      onClick={() => handlePagePerItems(15)}
                    >
                      15
                    </li>
                    <li
                      className="pagination__count-list"
                      onClick={() => handlePagePerItems(20)}
                    >
                      20
                    </li>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <div className="pagination__right">
        <div className="pagination__arrow" onClick={handleBackWord}>
          <img className="pagination__arrow__img" src={leftArrow} alt="" />
        </div>
        {pages.map((page, i) => (
          <div key={i}>
            {totalPage - startPage >= 7 ? (
              <>
                {page >= startPage && (
                  <>
                    {page < startPage + 5 && (
                      <p
                        className={`pagination__page-number ${
                          currentPage === page &&
                          "pagination__page-number__active"
                        }`}
                        onClick={() => {
                          setCurrentPage(page);
                        }}
                      >
                        {page}
                      </p>
                    )}
                    {page === startPage + 5 && (
                      <p className="pagination__pageDot">...</p>
                    )}
                    {page === totalPage && (
                      <p
                        className={`pagination__page-number ${
                          currentPage === page &&
                          "pagination__page-number__active"
                        }`}
                        onClick={() => {
                          setCurrentPage(page);
                        }}
                      >
                        {page}
                      </p>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {page >= startPage && (
                  <p
                    className={`pagination__page-number ${
                      currentPage === page && "pagination__page-number__active"
                    }`}
                    onClick={() => {
                      setCurrentPage(page);
                    }}
                  >
                    {page}
                  </p>
                )}
              </>
            )}
          </div>
        ))}

        <div
          className="pagination__arrow pagination__arrow--last"
          onClick={handleForWord}
        >
          <img className="pagination__arrow__img" src={rightArrow} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
