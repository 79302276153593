import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allUsers:[],
  selectedUsers: [],
  userSearchText: "",
  userActivitySearchText: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAllUsers: (state, action) => {
      state.allUsers = action.payload;
    },
    selectUsers: (state, action) => {
      state.selectedUsers = action.payload;
    },
    setUserSearchText: (state, action) => {
      state.userSearchText = action.payload;
    },
    setUserActivitySearchText: (state, action) => {
      state.userActivitySearchText = action.payload;
    },
    backToInitialSelectedUsers: (state) => {
      state.selectedUsers = [];
    },
    backToInitialUserSearch: (state) => {
      state.userSearchText = "";
    },
    backToInitialUserActivitySearch: (state) => {
      state.userActivitySearchText = "";
    },
  },
});

export const {
  setAllUsers,
  selectUsers,
  setUserSearchText,
  setUserActivitySearchText,
  backToInitialUserSearch,
  backToInitialSelectedUsers,
  backToInitialUserActivitySearch,
} = userSlice.actions;
export default userSlice.reducer;
