import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adminSearchText: "",
  allAdmins:[],
  currentAdmin:{}
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAllAdmins: (state, action) => {
      state.allAdmins = action.payload;
    },
    setCurrentAdmin: (state, action) => {
      state.currentAdmin = action.payload;
    },
    setAdminSearchText: (state, action) => {
      state.adminSearchText = action.payload;
    },
    backToInitialAdminSearch: (state) => {
      state.adminSearchText = "";
    },
  },
});

export const { setAllAdmins,setCurrentAdmin,setAdminSearchText, backToInitialAdminSearch } =
  adminSlice.actions;
export default adminSlice.reducer;
