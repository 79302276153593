import React from "react";
import * as FiIcons from "react-icons/fi";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import { IoDocumentsOutline } from "react-icons/io5";

export const AdminSidebarData = () => {
  const commonData = [
    {
      title: "Users",
      path: "#",
      icon: <FiIcons.FiUsers />,
      cName: "nav-text",
      subMenu: [
        {
          title: "User List",
          path: "/userList",
        },
        {
          title: "Add New",
          path: "/addUser",
        },
      ],
    },
    {
      title: "Settings",
      icon: <BsIcons.BsGear />,
      path: "/adminProfile",
      cName: "nav-text",
    },
    {
      title: "Report",
      path: "/report",
      icon: <IoDocumentsOutline />,
      cName: "nav-text",
    },
  ];
  const superAdminData = [
    {
      title: "Admins",
      path: "/adminList",
      icon: <MdIcons.MdOutlineAdminPanelSettings />,
      cName: "nav-text",
    },
  ];
  let isSuperAdmin = localStorage.getItem("is_super_admin");
  if (isSuperAdmin === "true") {
    let AdminData = [...commonData, ...superAdminData];
    return AdminData;
  } else {
    let AdminData = [...commonData];
    return AdminData;
  }
};
