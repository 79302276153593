import React from "react";
import { Navigate, useLocation } from "react-router-dom";

export const SuperRoute = ({ children }) => {
  let isSuperAdmin = localStorage.getItem("is_super_admin");
  const location = useLocation();

  return isSuperAdmin === "true" ? (
    children
  ) : (
    <Navigate to="*" replace state={{ from: location }} />
  );
};
