import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import ConfirmDeleteCard from "../../Modal/ConfirmDeleteCard/ConfirmDeleteCard";
import {
  handleCharSortLogic,
  handleNumberSortLogic,
} from "../../../Helper/Sorting/Sorting";
import { axiosInstance } from "../../../Helper/ApiCall/ApiCall";
import ConfirmStatusChangeCard from "../../Modal/ConfirmStatusChangeCard/ConfirmStatusChangeCard";
import { useNavigate } from "react-router-dom";

const AdminsTable = ({ admins, getAdmins, allAdmins, setAdmins }) => {
  const userId = localStorage.getItem("userId");
  const [deleteItem, setDeleteItem] = useState("");
  const [statusData, setStatusData] = useState("");
  const [sort, setSort] = useState(false);
  const [sortEvent, setSortEvent] = useState("");
  const navigate = useNavigate();

  const handleDeleteData = async (id) => {
    setDeleteItem(id);
  };

  const handleDelete = () => {
    const deleteStudent = async () => {
      const urlString = `admins/${deleteItem}`;
      const result = await axiosInstance.delete(urlString);
      if (result?.status === 204) {
        getAdmins();
        setDeleteItem("");
      }
      else if(result?.response?.data?.message==='Json Web Token is Expired, Try again '){
        navigate("/login");
      }
    };
    deleteStudent();
  };

  const handleSorting = (event, type) => {
    if (sortEvent !== "" && sortEvent !== event) {
      let data = [];
      if (type === "character") {
        data = handleCharSortLogic(allAdmins, event, "ascending");
      } else if (type === "number") {
        data = handleNumberSortLogic(allAdmins, event, "ascending");
      } else if (type === "serial") {
        data = handleNumberSortLogic(allAdmins, event, "descending");
      }
      setAdmins(data);
      setSort(true);
    } else {
      if (sort === true) {
        let data = [];
        if (type === "character") {
          data = handleCharSortLogic(allAdmins, event, "descending");
        } else if (type === "number") {
          data = handleNumberSortLogic(allAdmins, event, "descending");
        } else if (type === "serial") {
          data = handleNumberSortLogic(allAdmins, event, "ascending");
        }
        setAdmins(data);
      } else {
        let data = [];
        if (type === "character") {
          data = handleCharSortLogic(allAdmins, event, "ascending");
        } else if (type === "number") {
          data = handleNumberSortLogic(allAdmins, event, "ascending");
        } else if (type === "serial") {
          data = handleNumberSortLogic(allAdmins, event, "descending");
        }
        setAdmins(data);
      }
      setSort(!sort);
    }
    setSortEvent(event);
  };

  const handleChange = async (e, id) => {
    setStatusData({status:e.target.value,id:id})
  };
  const handleStatus=()=>{
    const newStatus = {
      status: statusData.status,
    };
    const updateAdmin = async () => {
      const urlString = `admins/${statusData.id}`;
      const result = await axiosInstance.put(urlString, newStatus);
      if (result?.data?.success === true) {
        getAdmins();
        setStatusData('')
      }else if(result?.response?.data?.message==='Json Web Token is Expired, Try again '){
        navigate("/login");
      }
    };
    updateAdmin();
  }

  return (
    <table className="table">
      <thead>
        <tr className="table__head">
          <th className="table__segment">
            <div className="table__head-element">
              <p>SERIAL</p>
              <div
                className="table__sort-arrow"
                onClick={() => handleSorting("serial", "serial")}
              >
                <FaIcons.FaAngleUp
                  className={`${
                    sortEvent === "serial" &&
                    sort === false &&
                    "table__sort-arrow__bold"
                  }`}
                />
                <FaIcons.FaAngleDown
                  className={`${
                    sortEvent === "serial" &&
                    sort === true &&
                    "table__sort-arrow__bold"
                  }`}
                />
              </div>
            </div>
          </th>

          <th className="table__segment">
            <div className="table__head-element">
              <p>NAME</p>
              <div
                className="table__sort-arrow"
                onClick={() => handleSorting("name", "character")}
              >
                <FaIcons.FaAngleUp
                  className={`${
                    sortEvent === "name" &&
                    sort === true &&
                    "table__sort-arrow__bold"
                  }`}
                />
                <FaIcons.FaAngleDown
                  className={`${
                    sortEvent === "name" &&
                    sort === false &&
                    "table__sort-arrow__bold"
                  }`}
                />
              </div>
            </div>
          </th>

          <th className="table__segment">
            <div className="table__head-element">
              <p>EMAIL</p>
              <div
                className="table__sort-arrow"
                onClick={() => handleSorting("email", "character")}
              >
                <FaIcons.FaAngleUp
                  className={`${
                    sortEvent === "email" &&
                    sort === true &&
                    "table__sort-arrow__bold"
                  }`}
                />
                <FaIcons.FaAngleDown
                  className={`${
                    sortEvent === "email" &&
                    sort === false &&
                    "table__sort-arrow__bold"
                  }`}
                />
              </div>
            </div>
          </th>

          <th className="table__segment">
            <div className="table__head-element">
              <p>PHONE NUMBER</p>
              <div
                className="table__sort-arrow"
                onClick={() => handleSorting("phone", "number")}
              >
                <FaIcons.FaAngleUp
                  className={`${
                    sortEvent === "phone" &&
                    sort === true &&
                    "table__sort-arrow__bold"
                  }`}
                />
                <FaIcons.FaAngleDown
                  className={`${
                    sortEvent === "phone" &&
                    sort === false &&
                    "table__sort-arrow__bold"
                  }`}
                />
              </div>
            </div>
          </th>
          <th className="table__segment">ROLE</th>
          <th className="table__segment">STATUS</th>
          <th className="table__segment--action">ACTION</th>
        </tr>
      </thead>

      <tbody>
        {admins?.map((admin, i) => (
          <tr key={i} className="table__raw">
            <td className="table__segment">{admin?.serial}</td>
            <td className="table__segment">{admin?.name}</td>
            <td className="table__segment">{admin?.email}</td>
            <td className="table__segment">{admin?.phone}</td>
            <td className="table__segment">
              {admin?.is_super_admin ? "Super Admin" : "Admin"}
            </td>
            <td className="table__segment">
              {!admin?.is_super_admin ? (
                <div>
                   <select
                  className="table__segment__select-field"
                  name="status"
                  value={admin?.status}
                  onChange={(e) => {
                    handleChange(e, admin._id);
                  }}
                >
                  <option value="active">active</option>
                  <option value="pending">pending</option>
                </select>
                {statusData.id=== admin._id && (
                  <div className="table__confirm-status-card">
                    <ConfirmStatusChangeCard
                      handleStatus={handleStatus}
                      setStatusData={setStatusData}
                    />
                  </div>
                )}
                </div>
              ) : (
                admin?.status
              )}
            </td>
            <td className="table__segment--action">
              <div className="table__btnDiv--single">
                <button
                  onClick={() => {
                    admin._id !== userId && handleDeleteData(admin._id);
                  }}
                  className={`${
                    admin._id !== userId
                      ? "table__btnDiv__delete-btn"
                      : "table__btnDiv__disable-btn"
                  }`}
                >
                  Delete
                </button>
              </div>
              {deleteItem === admin._id && (
                <div className="table__confirm-card">
                  <ConfirmDeleteCard
                    handleDelete={handleDelete}
                    setDeleteItem={setDeleteItem}
                  />
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AdminsTable;
