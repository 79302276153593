export const reportExport = (items, selected) => {
  const headers = [
    { label: "NAME", key: "name" },
    { label: "EMAIL", key: "email" },
    { label: "DATE", key: "date" },
    { label: "LEFT", key: "left" },
    { label: "RIGHT", key: "right" },
    { label: "TOP", key: "top" },
    { label: "BOTTOM", key: "bottom" },
  ];

  const selectedItems = items.filter((item) => selected.includes(item._id));
  const newArr = [];
  console.log(selectedItems);
  selectedItems.map((item, i) => {
    const ob = {
      name: item?.name,
      email: item?.email,
      date: item?.velocities[0]?.date,
      left: item?.velocities[0]?.left,
      right: item?.velocities[0]?.right,
      top: item?.velocities[0]?.top ,
      bottom: item?.velocities[0]?.bottom,
    };
    newArr.push(ob);
    for (let i = 1; i < item.velocities.length; i++) {
      const velocity = item.velocities[i];
      newArr.push({
        name: "",
        email: "",
        date: velocity?.date,
        left: velocity?.left,
        right: velocity?.right,
        top: velocity?.top,
        bottom: velocity?.bottom,
      });
    }
  });

  const csvReport = {
    data: newArr,
    headers: headers,
    filename: "Report.csv",
  };
  return csvReport;
};
