import React, { useEffect, useState } from "react";
import AddPageHeader from "../../Header/AddPageHeader/AddPageHeader";
import { useSelector, useDispatch } from "react-redux";
import { axiosInstance } from "../../../Helper/ApiCall/ApiCall";
import { setCurrentAdmin } from "../../../redux/adminSlice";
import { useNavigate } from "react-router-dom";

const PendingProfile = () => {
  const userId = localStorage.getItem("userId");
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentAdmin = useSelector((state) => state.admin.currentAdmin);

  const profileProps = {
    title: "Pending Profile",
    subTitleLeft: "Inactive",
    subTitleRight: "admin",
    logoutBtnText: "Logout",
  };

  const getAdmin = async () => {
    const urlString = `admins/${userId}`;
    const result = await axiosInstance.get(urlString);
    if (result?.data?.success === true) {
      dispatch(setCurrentAdmin(result?.data?.data));
    }else if(result?.response?.data?.message==='Json Web Token is Expired, Try again '){
      navigate("/login");
    } else {
      dispatch(setCurrentAdmin({}));
    }
  };

  useEffect(() => {
    if(!currentAdmin.name){
      getAdmin()
    }
  }, []);


  useEffect(() => {
    if(currentAdmin.name){
      setUser(currentAdmin);
    }
  }, [currentAdmin]);

  return (
    <div className="page">
      <div className="page__pending-profile">
        <div className="page__container">
          <div className="page__container__header">
            <AddPageHeader headerData={profileProps} />
          </div>
          <div className="page__profileDiv">
            <div className="page__profile-container">
              <div className="page__profile-left">
                <div className="page__profile-segment">
                  <label className="page__profile-segment__profile-label">
                    Name
                  </label>
                  <p className="page__profile-segment__profile-text">
                    {user?.name}
                  </p>
                </div>
                <div className="page__profile-segment">
                  <label className="page__profile-segment__profile-label">
                    Email
                  </label>
                  <p className="page__profile-segment__profile-text">
                    {user?.email || "--"}
                  </p>
                </div>
              </div>
              <div className="page__profile-right">
                <div className="page__profile-segment">
                  <label className="page__profile-segment__profile-label">
                    Profile Privilege
                  </label>
                  <p className="page__profile-segment__profile-text">
                    {user?.is_super_admin
                      ? "Super Administrator"
                      : "Administrator"}
                  </p>
                </div>
                <div className="page__profile-segment">
                  <label className="page__profile-segment__profile-label">
                    Phone Number
                  </label>
                  <p className="page__profile-segment__profile-text">
                    {user?.phone || "--"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="page__pending-message">
            <p>You are not activated yet!!</p>
            <p>Wait for Super Admin's response</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingProfile;
