import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";
import appLogo from "../../images/app_icon.png";
import "./Sidebar.scss";
import { AdminSidebarData } from "./AdminSidebarData";
import {
  activePageSet,
  showValueSet,
  activeSubPageSet,
  sellSpanSet,
  backToInitialSellSpan,
  backToInitialShow,
} from "../../redux/sidebarSlice";
import { axiosInstance } from "../../Helper/ApiCall/ApiCall";

const Sidebar = () => {
  const navigate = useNavigate();
  const [admin, setAdmin] = useState({});
  const dispatch = useDispatch();
  const activePage = useSelector((state) => state.sidebar.activePage);
  const activeSubPage = useSelector((state) => state.sidebar.activeSubPage);
  const sell = useSelector((state) => state.sidebar.sellSpan);
  const show = useSelector((state) => state.sidebar.show);

  useEffect(() => {
    const userName = localStorage.getItem("userName");
    const isSuperAdmin = localStorage.getItem("is_super_admin");
    setAdmin({
      name: userName,
      isSuperAdmin: isSuperAdmin,
    });
  }, []);

  const handleToggle = () => {
    dispatch(showValueSet(!show));
    if (show) {
      dispatch(backToInitialSellSpan());
    } else {
      dispatch(sellSpanSet(activePage + 1));
    }
  };

  const handleNavActivePage = (page) => {
    dispatch(activePageSet(page));
  };

  const handleSidebarPageActive = (page, path) => {
    dispatch(activePageSet(page));
    if (path) {
      handleSellSpan(page);
    }
  };

  const handleSellSpan = (i) => {
    if (sell === i + 1) {
      dispatch(backToInitialSellSpan());
    } else {
      if (show === false) {
        dispatch(backToInitialShow());
      }
      dispatch(sellSpanSet(i + 1));
    }
  };

  const handleSubPageActive = (page) => {
    dispatch(activeSubPageSet(page));
  };

  const handleLogout = async () => {
    const urlString = `auth/logout`;
    let result = await axiosInstance.post(urlString);
    if (result?.status === 204) {
      window.localStorage.clear();
      navigate("/login");
    }
    else if(result?.response?.data?.message==='Json Web Token is Expired, Try again '){
      navigate("/login");
    }
  };

  return (
    <main className="navigation">
      <header
        className={`navigation__header ${show && "navigation__header--fold"}`}
      >
        <div className="navigation__header__body">
          <div>
            <div className="navigation__toggle" onClick={handleToggle}>
              <div className="navigation__toggle__menu-div">
                <FaIcons.FaBars />
                <p className="navigation__menu-text">Menu</p>
              </div>
            </div>
          </div>

          <div className="navigation__header-right">
            <div className="navigation__top-link-list">
              <Link
                className={`navigation__top-nav-link ${
                  activePage === 0 && "navigation__top-nav-link--active"
                }`}
                to={"/userList"}
                onClick={() => handleNavActivePage(0)}
              >
                Users
              </Link>
              <Link
                className={`navigation__top-nav-link ${
                  activePage === 1 && "navigation__top-nav-link--active"
                }`}
                to={"/adminProfile"}
                onClick={() => handleNavActivePage(1)}
              >
                Settings
              </Link>
              <Link
                className={`navigation__top-nav-link ${
                  activePage === 2 && "navigation__top-nav-link--active"
                }`}
                to={"/report"}
                onClick={() => handleNavActivePage(2)}
              >
                Report
              </Link>
            </div>
            <div className="navigation__profile-div">
              <div className="navigation__profile-text-div">
                <p className="navigation__profile-text-div__text">
                  {admin?.name}
                </p>
                <p className="navigation__profile-text-div__sub-text">
                  {admin?.is_super_admin
                    ? "Super Administrator"
                    : "Administrator"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div
        className={`navigation__sidebar ${
          show ? "navigation__sidebar--show" : null
        }`}
      >
        <div>
          <div
            className={`navigation__app-div ${
              !show ? "navigation__app-div--small" : "navigation__app-div--show"
            }`}
          >
            <img src={appLogo} className={`navigation__logo ${
              !show && "navigation__logo--small"
            }`} alt="" />
          </div>

          <div>
            {AdminSidebarData().map((item, index) => {
              return (
                <div key={index}>
                  <Link
                    to={item.path}
                    className={`navigation__nav-link ${
                      activePage === index && "navigation__nav-link--active"
                    }`}
                    onClick={() =>
                      handleSidebarPageActive(
                        index,
                        item.subMenu ? true : false
                      )
                    }
                  >
                    <span className="navigation__nav-link-icon">
                      {item.icon}
                    </span>
                    <span
                      className={`navigation__nav-link-name ${
                        show && "navigation__nav-link-name--show"
                      }`}
                    >
                      {item.title}
                    </span>
                  </Link>
                  <AiIcons.AiOutlineRight
                    onClick={() => handleSellSpan(index)}
                    className={`navigation__drop-arrow ${
                      activePage === index && "navigation__drop-arrow--active"
                    } ${
                      sell === index + 1 &&
                      item.subMenu &&
                      "navigation__drop-arrow--rotate"
                    }`}
                  />
                  {item.subMenu && sell === index + 1 && (
                    <div className="navigation__sub-fields">
                      {item.subMenu.map((s, i) => (
                        <Link
                          key={i}
                          className={`navigation__sub-link  ${
                            activeSubPage === `${index}.${i}` &&
                            "navigation__sub-link--active"
                          }`}
                          onClick={() => handleSubPageActive(`${index}.${i}`)}
                          to={s.path}
                        >
                          {s.title}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
            <Link
              to={"#"}
              className="navigation__nav-link"
              onClick={handleLogout}
            >
              <span className="navigation__nav-link-icon">
                <MdIcons.MdLogout />
              </span>
              <span
                className={`navigation__nav-link-name ${
                  show && "navigation__nav-link-name--show"
                }`}
              >
                Logout
              </span>
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Sidebar;
