import React from "react";
import { useSelector } from "react-redux";
import * as FaIcons from "react-icons/fa";
import AddPageHeader from "../../Header/AddPageHeader/AddPageHeader";
import { axiosInstance } from "../../../Helper/ApiCall/ApiCall";
import { useNavigate } from "react-router-dom";

const UserProfile = ({ user, getUser }) => {
  const navigate = useNavigate();
  const show = useSelector((state) => state.sidebar.show);

  const profileProps = {
    title: "User Profile",
    subTitleLeft: "User",
    subTitleRight: "profile page",
  };

  const generateLoginCode = async () => {
    const urlString = `users/${user._id}/change_login_code`;
    const result = await axiosInstance.put(urlString);
    if (result?.data?.success === true) {
      getUser();
    }else if(result?.response?.data?.message==='Json Web Token is Expired, Try again '){
      navigate("/login");
    }

  };
  return (
    <div
      className={`page__container ${
        show ? "page__container--small" : "page__container--expand"
      }`}
    >
      <div className="page__container__header">
        <AddPageHeader headerData={profileProps} />
      </div>
      <div className="page__profileDiv">
        <div className="page__profile-container page__profile-container--user-profile">
          <div className="page__profile-segment">
            <label className="page__profile-segment__profile-label">Name</label>
            <p className="page__profile-segment__profile-text">
              {user?.name || "--"}
            </p>
          </div>
          <div className="page__profile-segment">
            <label className="page__profile-segment__profile-label">
              Email
            </label>
            <p className="page__profile-segment__profile-text">
              {user?.email || "--"}
            </p>
          </div>
          <div className="page__profile-segment">
            <label className="page__profile-segment__profile-label">
              Status
            </label>
            <p className="page__profile-segment__profile-text">
              {user?.status || "--"}
            </p>
          </div>
          <div className="page__profile-segment">
            <label className="page__profile-segment__profile-label">
              Login Code
            </label>
            <p className="page__profile-segment__profile-text">
              {user?.login_code || "--"}
            </p>
          </div>
        </div>
        <button
          className="page__submitBtn page__submitBtn--fill"
          onClick={generateLoginCode}
        >
          {" "}
          <span className="page__formBtn-icon page__formBtn-icon--refresh">
            <FaIcons.FaRecycle />
          </span>{" "}
          Regenerate Login Code{" "}
        </button>
      </div>
    </div>
  );
};

export default UserProfile;
