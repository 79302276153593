export const adminSearch = (dbAdmins, searchText) => {
  const matchAdmins = dbAdmins.filter((value) => {
    return (
      value.name.toLowerCase().includes(searchText.toLowerCase()) ||
      value.email.toLowerCase().includes(searchText.toLowerCase()) ||
      value.phone.toString().toLowerCase().includes(searchText.toLowerCase())
    );
  });
  return matchAdmins;
};
