import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ListHeader from "../../Header/ListHeader/ListHeader";
import Pagination from "../../Pagination/Pagination";
import Sidebar from "../../Sidebar/Sidebar";
import AdminsTable from "../../Table/AdminsTable/AdminsTable";
import {
  activePageSet,
  backToInitialSellSpan,
  backToInitialSubPage,
} from "../../../redux/sidebarSlice";
import { backToInitialAdminSearch, setAllAdmins } from "../../../redux/adminSlice";
import { backToInitialCurrentItems } from "../../../redux/paginationSlice";
import { axiosInstance } from "../../../Helper/ApiCall/ApiCall";
import { adminSearch } from "../../../Helper/AdminSearch/AdminSearch";
import { useNavigate } from "react-router-dom";

const AdminList = () => {
  const [admins, setAdmins] = useState([]);
  const [dbAdmins, setDbAdmins] = useState([]);
  const [currentAdmins, setCurrentAdmins] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const show = useSelector((state) => state.sidebar.show);
  const searchText = useSelector((state) => state.admin.adminSearchText);
  const allAdmins = useSelector((state) => state.admin.allAdmins);
  const reduxCurrentItems = useSelector(
    (state) => state.pagination.currentItems
  );

  const adminProps = {
    title: "Admin",
    subTitleLeft: "Current Data",
    subTitleRight: `of ${admins?.length} admins`,
    placeholder: "by name,email & phone",
    page: "AdminList",
  };

  const getAdmins = async () => {
    const urlString = `admins`;
    let result = await axiosInstance.get(urlString);
    if (result?.data?.success === true) {
      const newAdmins = result?.data?.data;
      newAdmins.map((admin, i) => {
        admin.serial = i + 1;
      });
      dispatch(setAllAdmins(newAdmins))
    }else if(result?.response?.data?.message==='Json Web Token is Expired, Try again '){
      navigate("/login");
    } else {
      dispatch(setAllAdmins([]))
    }
  };

  useEffect(() => {
    dispatch(backToInitialAdminSearch());
    dispatch(backToInitialCurrentItems());
    dispatch(activePageSet(3));
    dispatch(backToInitialSellSpan());
    dispatch(backToInitialSubPage());
  }, []);

  useEffect(() => {
    if(allAdmins.length<=0){
      getAdmins()
    }
  }, []);


  useEffect(() => {
    if(allAdmins.length>0){
      setAdmins(allAdmins);
      setDbAdmins(allAdmins);
    }
  }, [allAdmins]);

  useEffect(() => {
    if (searchText !== "") {
      const newAdmins = adminSearch(dbAdmins, searchText);
      setAdmins(newAdmins);
    } else {
      setAdmins(dbAdmins);
    }
  }, [searchText, dbAdmins]);

  useEffect(() => {
    setCurrentAdmins(reduxCurrentItems);
  }, [reduxCurrentItems]);
  return (
    <div className="page">
      <Sidebar />
      <div
        className={`page__container ${
          show ? "page__container--small" : "page__container--expand"
        }`}
      >
        <div className="page__container__header">
          <ListHeader
            headerData={adminProps}
            items={dbAdmins}
            setState={setAdmins}
          />
        </div>
        {admins.length > 0 ? (
          <div className="page__container__table-div">
            <AdminsTable
              admins={currentAdmins}
              allAdmins={admins}
              setAdmins={setAdmins}
              getAdmins={getAdmins}
            />
          </div>
        ) : (
          <p className="page__container__no-match">No items found..</p>
        )}
        {admins.length > 0 && (
          <div className="page__container__pagination-div">
            <Pagination items={admins} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminList;
